import {
  SET_USER_DATA,
  SET_ERROR,
  SET_LOADING,
  RESET_DATA,
  UPDATE_PROFILE_INFO_START,
  UPDATE_PROFILE_INFO_COMPLETE,
  UPDATE_PROFILE_INFO_ERROR,
  EMAIL_VERIFICATION_START,
  EMAIL_VERIFICATION_COMPLETE,
  EMAIL_VERIFICATION_ERROR,
  SEND_EMAIL_VERIFICATION_START,
  SEND_EMAIL_VERIFICATION_COMPLETE,
  SEND_EMAIL_VERIFICATION_ERROR,
  SEND_PHONE_VERIFICATION_START,
  SEND_PHONE_VERIFICATION_COMPLETE,
  SEND_PHONE_VERIFICATION_ERROR,
  PHONE_VERIFICATION_START,
  PHONE_VERIFICATION_COMPLETE,
  PHONE_VERIFICATION_ERROR,
  CLEAR_PROFILE_ERROR,
  REQUEST_GET_USER_PROFILE_START,
  REQUEST_GET_USER_PROFILE_COMPLETE,
  GET_PHYISICIAN_INSTITUTE_LIST_START,
  GET_PHYISICIAN_INSTITUTE_LIST_COMPLETE,
  GET_PHYISICIAN_INSTITUTE_LIST_ERROR,
  GET_PROFESSION_LIST_START,
  GET_PROFESSION_LIST_COMPLETE,
  GET_PROFESSION_LIST_ERROR,
  GET_SPECIALTY_DROPDOWN_LIST_START,
  GET_SPECIALTY_DROPDOWN_LIST_COMPLETE,
  GET_SPECIALTY_DROPDOWN_LIST_ERROR,
  COMPLETE_PROFILE_START,
  COMPLETE_PROFILE_COMPLETE,
  COMPLETE_PROFILE_ERROR,
  SET_ONBOARDING_DATA,
  SET_VIDEO_MODAL_DISPLAY,
  CREATE_NEW_USER_START,
  CREATE_NEW_USER_COMPLETE,
  CREATE_NEW_USER_ERROR,
  GET_REQUESTERS_START,
  GET_REQUESTERS_ERROR,
  GET_REQUESTERS_COMPLETE,
  GET_USERS_CONFIG_START,
  GET_USERS_CONFIG_COMPLETE,
  GET_USERS_CONFIG_ERROR,
} from './actions';

let initialState = {
  userProfileError: '',
  isLoading: false,
  isShowError: false,
  waitingForInstituteListTerms: false,
  isUpdatedProfileCompleted: false,
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_USER_DATA:
      return { ...state, userData: payload.data };

    case UPDATE_PROFILE_INFO_START:
      return {
        ...state,
        isProfileUpdateStarted: true,
        updateProfileError: undefined,
        isUpdatedProfileCompleted: false,
      };
    case UPDATE_PROFILE_INFO_COMPLETE:
      return {
        ...state,
        updateProfileError: undefined,
        isProfileUpdateStarted: false,
        isUpdatedProfileCompleted: true,
        userData: payload.data,
      };
    case UPDATE_PROFILE_INFO_ERROR:
      return {
        ...state,
        isProfileUpdateStarted: false,
        updateProfileError: payload.error,
        isUpdatedProfileCompleted: false,
        ...payload,
      };

    case GET_PHYISICIAN_INSTITUTE_LIST_START:
      return {
        ...state,
        physicianInstituteError: undefined,
        waitingForInstituteListTerms: true,
      };
    case GET_PHYISICIAN_INSTITUTE_LIST_COMPLETE:
      return {
        ...state,
        physicianInstituteError: undefined,
        instituteData: payload.data,
        waitingForInstituteListTerms: false,
      };
    case GET_PHYISICIAN_INSTITUTE_LIST_ERROR:
      return {
        ...state,
        physicianInstituteError: payload.error,
        ...payload,
        waitingForInstituteListTerms: false,
      };
    case GET_PROFESSION_LIST_START:
      return {
        ...state,
        waitingForProfessionList: true,
      };
    case GET_PROFESSION_LIST_COMPLETE:
      return {
        ...state,
        professionData: payload.data,
        waitingForProfessionList: false,
      };
    case GET_PROFESSION_LIST_ERROR:
      return {
        ...state,
        ...payload,
        waitingForProfessionList: false,
      };
    case GET_REQUESTERS_START:
      return {
        ...state,
        getRequestersError: undefined,
      };
    case GET_REQUESTERS_COMPLETE:
      return {
        ...state,
        requesters: payload.requesters,
        getRequestersError: undefined,
      };
    case GET_REQUESTERS_ERROR:
      return { ...state, getRequestersError: payload.error, ...payload };

    case GET_SPECIALTY_DROPDOWN_LIST_START:
      return {
        ...state,
        waitingForSpecialtyList: true,
      };
    case GET_SPECIALTY_DROPDOWN_LIST_COMPLETE:
      return {
        ...state,
        specialtyDropDownData: payload.data,
        waitingForSpecialtyList: false,
      };
    case GET_SPECIALTY_DROPDOWN_LIST_ERROR:
      return {
        ...state,
        ...payload,
        waitingForSpecialtyList: false,
      };
    case COMPLETE_PROFILE_START:
      return {
        ...state,
      };
    case COMPLETE_PROFILE_COMPLETE:
      return {
        ...state,
      };
    case COMPLETE_PROFILE_ERROR:
      return {
        ...state,
        ...payload,
      };
    case REQUEST_GET_USER_PROFILE_START:
      return {
        ...state,
        isFetchProfileCompleted: false,
      };
    case REQUEST_GET_USER_PROFILE_COMPLETE:
      return {
        ...state,
        isFetchProfileCompleted: true,
      };
    case SEND_EMAIL_VERIFICATION_START:
      return {
        ...state,
        isSendEmailVerificationStart: true,
        sendEmailVerificationError: undefined,
        sendEmailSuccessMessage: undefined,
      };
    case SEND_EMAIL_VERIFICATION_COMPLETE:
      return {
        ...state,
        isSendEmailVerificationStart: false,
        sendEmailVerificationError: undefined,
        sendEmailSuccessMessage: payload.data,
      };
    case SEND_EMAIL_VERIFICATION_ERROR:
      return {
        ...state,
        isSendEmailVerificationStart: false,
        sendEmailVerificationError: payload.error,
        sendEmailSuccessMessage: undefined,
        ...payload,
      };
    case SEND_PHONE_VERIFICATION_START:
      return {
        ...state,
        sendPhoneVerificationError: undefined,
        sendPhoneSuccessMessage: undefined,
      };
    case SEND_PHONE_VERIFICATION_COMPLETE:
      return {
        ...state,
        sendPhoneVerificationError: undefined,
        sendPhoneSuccessMessage: payload.data,
      };
    case SEND_PHONE_VERIFICATION_ERROR:
      return {
        ...state,
        sendPhoneVerificationError: payload.error,
        sendPhoneSuccessMessage: undefined,
        ...payload,
      };
    case GET_USERS_CONFIG_START:
      return {
        ...state,
        usersConfig: undefined,
        isLoadingUsersConfig: true,
      };
    case GET_USERS_CONFIG_COMPLETE:
      return {
        ...state,
        usersConfig: payload.data,
        isLoadingUsersConfig: false,
      };
    case GET_USERS_CONFIG_ERROR:
      return {
        ...state,
        usersConfig: undefined,
        isLoadingUsersConfig: false,
        ...payload,
      };
    case CREATE_NEW_USER_START:
      return {
        ...state,
        isNewUserCreateStarted: true,
        createNewUserError: undefined,
      };
    case CREATE_NEW_USER_COMPLETE:
      return {
        ...state,
        isNewUserCreateStarted: false,
        createNewUserError: undefined,
      };
    case CREATE_NEW_USER_ERROR:
      return {
        ...state,
        createNewUserError: payload.error,
        isNewUserCreateStarted: false,
        ...payload,
      };

    case PHONE_VERIFICATION_START:
      return {
        ...state,
        phoneVerificationError: undefined,
        phoneVerificationSuccessMessage: undefined,
      };
    case PHONE_VERIFICATION_COMPLETE:
      return {
        ...state,
        phoneVerificationError: undefined,
        phoneVerificationSuccessMessage: payload.data,
      };
    case PHONE_VERIFICATION_ERROR:
      return {
        ...state,
        phoneVerificationError: payload.error,
        phoneVerificationSuccessMessage: undefined,
        ...payload,
      };
    case EMAIL_VERIFICATION_START:
      return {
        ...state,
        emailVerificationError: undefined,
        successMessage: undefined,
      };
    case EMAIL_VERIFICATION_COMPLETE:
      return {
        ...state,
        successMessage: payload.data,
        emailVerificationError: undefined,
      };
    case EMAIL_VERIFICATION_ERROR:
      return {
        ...state,
        emailVerificationError: payload.error,
        successMessage: undefined,
        ...payload,
      };
    case CLEAR_PROFILE_ERROR:
      return {
        ...state,
        updateProfileError: undefined,
        sendEmailVerificationError: undefined,
        sendEmailSuccessMessage: undefined,
        sendPhoneVerificationError: undefined,
        phoneVerificationSuccessMessage: undefined,
        sendPhoneSuccessMessage: undefined,
        phoneVerificationError: undefined,
        createNewUserError: undefined,
        ...payload,
      };
    case SET_ERROR:
      return {
        ...state,
        userProfileError: payload.error,
        isFetchProfileCompleted: true,
        isShowError: true,
      };
    case SET_ONBOARDING_DATA:
      return {
        ...state,
        onboardingData: payload,
        ...payload,
      };
    case SET_VIDEO_MODAL_DISPLAY:
      return {
        ...state,
        displayVideoModal: payload,
        ...payload,
      };
    case SET_LOADING:
      return { ...state, isLoading: payload.data };
    case RESET_DATA:
      return { ...state, userProfileError: '', isShowError: false };
    default:
      return state;
  }
};

export default reducer;
