import React, { useState, useEffect, useRef } from 'react';
import SearchForm from './SearchForm';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PrognostorgramOrderDetails from 'components/PrognostorgramOrderDetails';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthLoader from '../components/AuthLoader';
import { dateFormat } from '../utils/dateFormats';
import { ButtonSecondary, ButtonLink, ButtonCustom } from 'components/form-input/Button';
import NoOrder from 'components/NoOrder';
import OrderEmptyIcon from '../assets/images/NoResults.png';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import DashboardHeader from '../components/DashboardHeader';
import {
  AccordionWithHeader,
  AccordionNode,
  AccordionHeader,
  AccordionPanel,
} from 'react-accordion-with-header';
import { HiOutlineChevronDown } from 'react-icons/hi';
import PicotOptions from 'components/PicotOptions';
import {
  setOrderFormData,
  deleteOrder,
  getSearchData,
  setSearchQueryText,
  setOrderScopeData,
  setOrderIndexData,
  resetOrderIndexData,
  getorderByGuid,
  resetSingleOrderData,
  resetDownloadLink,
  resetPrognostogramPdf,
} from 'redux/modules/orderDetails/actions';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import orderScopeEnum from '../constants/enum/orderScopeEnum';
import CustomModal from '../components/CustomModal';
import { VscChromeClose } from 'react-icons/vsc';
import InfiniteScroll from 'react-infinite-scroll-component';
import { highlightText } from '../utils/highlightText';
import highlightWords from 'highlight-words';
import { getRawToken } from '../utils/validations';
import { QUESTION_TEXT_SIZE } from 'constants/Constants';
import { setSpecialtyText, getSpecialtyList, setUploadedFile } from 'redux/modules/orderDetails/actions';
import { useMixpanel } from 'react-mixpanel-browser';
import { ORDER_PAGINATION_SIZE } from '../constants/Constants';
import { RiErrorWarningFill } from 'react-icons/ri';
import resourceEnum from '../constants/enum/resourceEnum';

const SearchResult = () => {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const ref = useRef(null);
  const { getIdTokenClaims } = useAuth0();
  const history = useHistory();
  const { userData } = useSelector(({ userProfile }) => userProfile);
  const {
    getOrderBySearchError,
    isOrderLoading,
    searchListData,
    questionData,
    pdfFile,
    searchData,
    deleteOrderError,
    orderScopeData,
    searchPageNumber,
    hasMoreSearchOrders,
    count,
    orderListCount = 0,
    orderIndexData,
    specialtyTextData,
    singleOrderData,
    getOrderDetailError,
    isgettingOrderDetails,
    fileDowloadLink,
    resource,
    isSearchOrderLoading
  } = useSelector(({ orderDetails }) => orderDetails);
  const {
    first_name = '',
    email = '',
    last_name = '',
    institute = {},
  } = userData || {};
  let { tabID, guid } = useParams();
  const [tabIndex, setTabIndex] = useState(
    orderScopeData?.scopeTabIndex ? orderScopeData.scopeTabIndex : 0,
  );
  const [showModal, setShowModal] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [clickedTitleKey, setClickedTitleKey] = useState(null);
  const [specialtyTag, setSpecialtyTag] = useState(
    specialtyTextData ? specialtyTextData?.specialtyText : '',
  );
  const [dataSourceTag, setDataSourceTag] = useState('');
  const [motivationTag, setMotivationTag] = useState('');
  const [isDisplayOrder, setDisplayOrder] = useState(false);

  const [orderScope, setOrderScope] = useState(
    orderScopeData?.orderScope ? orderScopeData.orderScope : orderScopeEnum.PERSONAL,
  );
  const [isLoadedFirstTime, setLoadedFirstTime] = useState(true);
  const [orderIndex, setOrderIndex] = useState(
    orderIndexData?.index !== undefined ? orderIndexData?.index : null,
  );
  const [isOrderOpednedFromPortal, setIsOrderOpednedFromPortal] = useState(
    orderIndexData?.isOpened !== undefined ? orderIndexData?.isOpened : false,
  );
  const [scrollElement, setScrollElement] = useState(null);
  const handleClick = () => {
    dispatch(resetOrderIndexData({}));
    history.push(urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE);
  };

  const [orderPageNumber, setOrderPageNumber] = useState(
    searchData?.pageNumber ? searchData?.pageNumber : 1,
  );

  const [sortOrderBy, setSortOrderBy] = useState('newest-first');

  useEffect(() => {
    if (
      fileDowloadLink !== undefined &&
      fileDowloadLink !== '' &&
      resource === resourceEnum.ARTIFACTS
    ) {
      window.open(fileDowloadLink);
      dispatch(resetDownloadLink());
    }
  }, [fileDowloadLink]);

  useEffect(() => {
    if (pdfFile !== undefined && pdfFile !== '') {
      window.open(pdfFile);
      dispatch(resetPrognostogramPdf());
    }
  }, [pdfFile]);

  useEffect(() => {
    if (guid && !isOrderOpednedFromPortal) {
      getOrder();
    }
  }, [guid, isOrderOpednedFromPortal]);

  const getOrder = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      getorderByGuid({
        token: getRawToken(token),
        guid: guid,
      }),
    );
  };

  const redirectToSearchResult = (index = tabIndex, guid = '') => {
    var url = '';
    url =
      index === 1
        ? urlConstantsEnum.LIBRARY_SEARCH_RESULT
        : urlConstantsEnum.SEARCH_RESULT;
    url += guid ? '/' + guid : '';
    history.push(url);
  };

  const redirectToDashboard = (index = tabIndex) => {
    history.push(
      index === 1 ? urlConstantsEnum.LIBRARY_DASHBOARD : urlConstantsEnum.DASHBOARD,
    );
  };

  useEffect(() => {
    if (
      guid !== undefined &&
      orderIndex === null &&
      !isOrderOpednedFromPortal &&
      singleOrderData
    ) {
      setDisplayOrder(true);
    } else {
      setDisplayOrder(false);
    }
  }, [guid, orderIndex, isOrderOpednedFromPortal, singleOrderData]);

  const OnClickOfOrderClose = (isOwner) => {
    var tab = isOwner !== undefined && isOwner === true ? 0 : 1;
    dispatch(resetSingleOrderData({}));
    setDisplayOrder(false);
    var element = document.getElementsByClassName('react-tabs__tab');
    element[tab]?.click();
    if (searchData?.searchText !== undefined) {
      redirectToSearchResult(tab);
    } else {
      redirectToDashboard(tab);
    }
  };

  const getFirstPageOrders = (
    status,
    orderBy,
    specialty,
    dataSourceValue,
    providerMotivationValue,
    isCloseOpenedOrder,
  ) => {
    if (specialty || dataSourceValue || providerMotivationValue || isCloseOpenedOrder) {
      setOrderIndex(null);
      redirectToSearchResult();
    }
    setSortOrderBy(orderBy);
    setSpecialtyTag(specialty);
    setDataSourceTag(dataSourceValue);
    setMotivationTag(providerMotivationValue);
    dispatch(
      setSpecialtyText({
        specialtyText: specialty,
      }),
    );
    if (isLoadedFirstTime) {
      setLoadedFirstTime(false);
    }
    if (!isLoadedFirstTime) {
      searchOrder(
        searchData?.searchText,
        orderScope,
        orderBy,
        1,
        specialty,
        dataSourceValue,
        providerMotivationValue,
      );
    }
  };

  const getFirstPageOrdersBySpecialty = (specialty) => {
    guid = '';
    redirectToSearchResult();
    setSpecialtyTag(specialty);
    setOrderIndex(null);
    dispatch(
      setSpecialtyText({
        specialtyText: specialty,
      }),
    );
    searchOrder(searchData?.searchText, orderScope, sortOrderBy, 1, specialty);
  };

  const getFirstPageOrdersByDataSet = (dataset) => {
    guid = '';
    redirectToSearchResult();
    setOrderIndex(null);
    setDataSourceTag(dataset);
    searchOrder(
      searchData?.searchText,
      orderScope,
      sortOrderBy,
      1,
      specialtyTag,
      dataset,
    );
  };

  const getFirstPageOrdersByMotivation = (motivation) => {
    guid = '';
    redirectToSearchResult();
    setOrderIndex(null);
    setMotivationTag(motivation);
    searchOrder(
      searchData?.searchText,
      orderScope,
      sortOrderBy,
      1,
      specialtyTag,
      dataSourceTag,
      motivation,
    );
  };

  const setTitleValue = () => {
    const classExists =
      document.getElementsByClassName('accordian-header header-is-expanded').length > 0;

    if (!classExists) {
      setShowTitle(true);
    } else {
      setShowTitle(false);
    }
  };

  const wordsTruncate = (words, length, i, clinicalQuestionHighlifghtText) => {
    if (length >= words.length) {
      return highlightText(words, clinicalQuestionHighlifghtText);
    } else {
      var newTitle = words.substring(0, length);
      if (showTitle && i === clickedTitleKey) {
        return (
          <div
            className="titleDiv"
            onClick={() => {
              setTitleValue();
              setClickedTitleKey(i);
            }}
          >
            {highlightText(words, clinicalQuestionHighlifghtText)} ...
            <button
              className="seeMore"
              onClick={(event) => {
                event?.stopPropagation();
                setClickedTitleKey(i);
                setShowTitle(false);
              }}
              id={'btn-hide-title-button'}
            >
              hide
            </button>
          </div>
        );
      } else {
        const highlightTextQuery = clinicalQuestionHighlifghtText;
        var highlightedTitle;
        var showEllipsesAtEnd = false;
        if (highlightTextQuery.length !== 0) {
          var queryText;
          var clipDigit = 0;
          if (highlightTextQuery.length > 1) {
            var highlightTextQuery1 = '(' + highlightTextQuery.join('|') + ')';
            queryText = new RegExp(highlightTextQuery1);
            clipDigit = 10;
          } else {
            var position = words.lastIndexOf(highlightTextQuery[0]);
            if (position + highlightTextQuery[0].length > QUESTION_TEXT_SIZE) {
              queryText = highlightTextQuery[0];
              clipDigit = 15;
            }
          }
          if (queryText) {
            highlightedTitle = highlightWords({
              text: words,
              query: queryText,
              matchExactly: false,
              clipBy: clipDigit,
            });
          }
        }
        return (
          <div
            className="titleDiv"
            onClick={() => {
              setTitleValue();
              setClickedTitleKey(i);
            }}
          >
            {highlightTextQuery.length !== 0 && highlightedTitle ? (
              highlightedTitle?.map(({ key, text, match }) => {
                if (highlightedTitle[highlightedTitle.length - 1].key === key) {
                  var trimedText = text.trimEnd();
                  if (trimedText.slice(-3) !== '...') {
                    showEllipsesAtEnd = true;
                  } else {
                    showEllipsesAtEnd = false;
                  }
                }
                return (
                  <span key={key} className={match ? 'highlight' : ''}>
                    {highlightText(text, clinicalQuestionHighlifghtText)}
                  </span>
                );
              })
            ) : (
              <span>{highlightText(newTitle, clinicalQuestionHighlifghtText)} ...</span>
            )}
            {showEllipsesAtEnd && ' ...'}
            <button
              className="seeMore"
              onClick={(event) => {
                event?.stopPropagation();
                setClickedTitleKey(i);
                setShowTitle(true);
              }}
              id={'btn-see-more-title-button'}
            >
              see more
            </button>
          </div>
        );
      }
    }
  };

  const actionCallback = (panels, state) => {
    dispatch(resetDownloadLink());
    dispatch(resetOrderIndexData({}));
    if (state.active[0] !== undefined) {
      let orderIndex = state.active[0];
      setOrderIndex(orderIndex);
      setShowTitle(true);
      setIsOrderOpednedFromPortal(true);
      guid = searchListData[orderIndex].guid;
      redirectToSearchResult(tabIndex, searchListData[orderIndex].guid);
    } else {
      setOrderIndex(null);
      setIsOrderOpednedFromPortal(false);
      setShowTitle(false);
      guid = '';
      redirectToSearchResult();
    }
  };

  useEffect(() => {
    if (orderIndex !== null) {
      redirectToSearchResult(tabIndex, searchListData[orderIndex].guid);
      mixpanel.track('Screen path changed event', {
        screen_path: window.location.pathname,
      });
    }
  }, [orderIndex]);

  useEffect(() => {
    setScrollElement(document.querySelector('.single-prognostorm'));
  });

  useEffect(() => {
    if (scrollElement !== null) {
      scrollToOrder();
    }
  }, [scrollElement, orderIndex]);

  const scrollToOrder = () => {
    if (orderIndex !== null) {
      var scrollElement = document.querySelector(
        '.single-prognostorm:nth-child(' + orderIndex + ') .details',
      );
      if (scrollElement) {
        const { innerHeight: height } = window;
        const eleTop = scrollElement.getBoundingClientRect().top;
        if (innerHeight - eleTop < 340 || eleTop < -100) {
          window.scroll({
            top: document.querySelector(
              '.single-prognostorm:nth-child(' + orderIndex + ')',
            ).offsetTop,
            behavior: 'smooth',
          });
        }
      }
    }
  };

  const displayOrder = () => {
    return (
      <>
        {isSearchOrderLoading && orderPageNumber === 1 ? (
          <div className="position-relative">
            <AuthLoader fullScreen={false} />
          </div>
        ) : (
          <>
            <div className="mb-md-5 mb-0"></div>
            {!!getOrderBySearchError ? (
              <div className="text-center color-error error-text">
                {!isDisplayOrder ? getOrderBySearchError : ''}
              </div>
            ) : (
              showOrders()
            )}
          </>
        )}
      </>
    );
  };

  const fetchMoreData = () => {
    if (hasMoreSearchOrders) {
      searchOrder(
        searchData?.searchText,
        orderScope,
        sortOrderBy,
        searchPageNumber,
        specialtyTag,
      );
    }
  };

  const showOrders = () => {
    if (
      searchListData !== undefined &&
      searchListData?.length !== 0 &&
      Array.isArray(searchListData)
    ) {
      return (
        <InfiniteScroll
          dataLength={searchListData?.length}
          next={fetchMoreData}
          hasMore={hasMoreSearchOrders}
          loader={
            <div className="position-relative">
              <AuthLoader fullScreen={false} />
            </div>
          }
        >
          <AccordionWithHeader
            className="accordian-container  mt-4 mt-md-0"
            actionCallback={actionCallback}
            active={orderIndex !== null ? [orderIndex] : []}
          >
            {searchListData?.map((order, i) => {
              var bgColor = '';
              if (questionData) {
                for (let i = 0; i < questionData?.length; i++) {
                  if (questionData[i].name === order.question_type) {
                    bgColor = questionData[i].style.backgroundColor;
                  }
                }
              }
              var clinicalQuestionHighlifghtText =
                order.highlight_context &&
                order.highlight_context.hasOwnProperty('clinical_questions')
                  ? order.highlight_context.clinical_questions.text
                  : [];
              return (
                <AccordionNode
                  key={i}
                  className="single-prognostorm pt-4 pb-1 position-relative text-bold"
                >
                  <AccordionHeader className={`accordian-header`}>
                    <div className="title mt-1 mb-3 pr-6 pr-md-6" id={`order-title-${i}`}>
                      {order.picot_status === true
                        ? order.question_title
                          ? wordsTruncate(
                              order.question_title,
                              QUESTION_TEXT_SIZE,
                              i,
                              clinicalQuestionHighlifghtText,
                            )
                          : wordsTruncate(
                              order.clinical_questions,
                              QUESTION_TEXT_SIZE,
                              i,
                              clinicalQuestionHighlifghtText,
                            )
                        : wordsTruncate(
                            order.clinical_questions,
                            QUESTION_TEXT_SIZE,
                            i,
                            clinicalQuestionHighlifghtText,
                          )}
                    </div>

                    <div
                      className="position-absolute down-arrow"
                      id={`order-arrow-${i}`}
                      onClick={() => {
                        setClickedTitleKey(i);
                        setTitleValue();
                      }}
                    >
                      <ButtonLink
                        buttonAction="order expand button clicked"
                        id={`order-arrow-${i}`}
                      >
                        <HiOutlineChevronDown />
                      </ButtonLink>
                    </div>
                  </AccordionHeader>
                  <PrognostorgramOrderDetails
                    uuid={order.case_uuid ? order.case_uuid : ''}
                    email={order.email_address ? order.email_address : ''}
                    date={
                      tabIndex === 0
                        ? dateFormat(order.order_date)
                        : dateFormat(order.order_completed_date)
                    }
                    tag={order.question_type}
                    uniqueKey={`order-${i}-${order.modified_date}`}
                    color={bgColor}
                    questionStatus={order.status}
                    specialtyTag={order.provider_specialty}
                    textToHighlight={order.highlight_context}
                    tabIndex={tabIndex}
                    filterBySpecialty={() =>
                      getFirstPageOrdersBySpecialty(order.provider_specialty)
                    }
                    filterByDataSet={() => getFirstPageOrdersByDataSet(order.data_source)}
                    filterByMotivation={getFirstPageOrdersByMotivation}
                    dataSource={order.data_source}
                    motivations={order.motivations}
                  />
                  <AccordionPanel className="accordian-panel">
                    <PicotOptions
                      orderGuid={order.guid}
                      population={order.population}
                      intervention={order.intervention}
                      control={order.control}
                      outcome={order.outcome}
                      timeframe={order.timeframe}
                      onModify={() => modifyOrder(order, i)}
                      onEdit={() => editOrder(order, i)}
                      questionStatus={order.status}
                      onCancelOrder={() => cancelOrder(order.guid)}
                      tabIndex={tabIndex}
                      pdfFile={pdfFile}
                      textToHighlight={order.highlight_context}
                      conclusion={order.conclusion}
                      summary={order.summary}
                    />
                  </AccordionPanel>
                </AccordionNode>
              );
            })}
          </AccordionWithHeader>
        </InfiniteScroll>
      );
    } else {
      return (
        <NoOrder
          imgUrl={OrderEmptyIcon}
          headerText="No results found"
          bodyText={bodyTextTemplate}
        />
      );
    }
  };

  const cancelOrder = async (orderGuid) => {
    redirectToSearchResult();
    const token = await getIdTokenClaims();
    dispatch(
      deleteOrder({
        token: getRawToken(token),
        orderGuid: orderGuid,
        deleteOrderCallback: showModalOnDelete,
      }),
    );
  };

  const showModalOnDelete = () => {
    setOrderIndex(null);
    dispatch(resetOrderIndexData({}));
    searchOrder();
    setShowModal(true);
  };

  const OnClickOfClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  const editOrder = (order, i) => {
    const { documents } = searchListData[i];
    setOrderIndex(i);
    dispatch(
      setOrderIndexData({
        index: i,
        orders: searchListData,
        searchPageNumber: searchPageNumber,
        orderGuid: searchListData[i].guid,
        isOpened: true,
      }),
    );
    if(documents && documents.length > 0){
      const docArr = documents.map(doc => ({...doc, is_uploaded: false}));
      dispatch(setUploadedFile(docArr));
    }
    modifyAndOrderAgain(order, true);
  };
  const modifyOrder = (order, i) => {
    const { documents } = searchListData[i];
    setOrderIndex(i);
    dispatch(
      setOrderIndexData({
        index: i,
        orders: searchListData,
        searchPageNumber: searchPageNumber,
        orderGuid: searchListData[i].guid,
        isOpened: true,
      }),
    );
    if(documents && documents.length > 0){
      const docArr = documents.map(doc => ({...doc, is_uploaded: false}));
      dispatch(setUploadedFile(docArr));
    }
    modifyAndOrderAgain(order, false);
  };

  const modifyAndOrderAgain = (order, edit) => {
    dispatch(
      setOrderFormData({
        firstName: first_name,
        lastName: last_name,
        email: email,
        physicianInstitution: institute?.name,
        clinicalQuestion: order.clinical_questions
          ? order.clinical_questions
          : order.question_title,
        previous_order_guid: order.guid,
        question_type: order.question_type,
        questionTitle: order.question_title
          ? order.question_title
          : order.clinical_questions,
        population: order.population,
        intervention: order.intervention,
        control: order.control,
        outcome: order.outcome,
        timeframe: order.timeframe,
        picotSwitch: order.picot_status,
        orderStatus: order.status,
        isEditOrder: edit,
        institute_name: institute?.name,
        institute_guid: '',
        data_source: order.data_source,
        sharing_type: order.sharing_type,
        urgencyType: order.is_urgent,
        documentFileName: order.document,
        phiDisclaimer: order.phi_disclaimer,
        documentKeyPath: order.key_path,
        motivations: order.motivations,
        requester: order.requester,
        patient: order.patient,
      }),
    );
    history.push({
      pathname: `${urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE}/${
        edit === true ? 'edit' : 'reorder'
      }/${order.guid}`,
      state: {
        isFromSearch: true,
        searchText: searchData?.searchText,
      },
    });
  };
  const bodyTextTemplate = () => {
    return (
      <div className="no-order-text my-3">
        Please try adjusting your search to find what you’re looking for, or order a new
        prognostogram{' '}
        <ButtonSecondary
          id={'btn-order-new-pg-button'}
          cssClass="link"
          onClick={handleClick}
          label="here"
          buttonAction="Order a prognostogram button clicked"
        />{' '}
        .
      </div>
    );
  };

  const searchOrder = async (
    search_query = searchData?.searchText,
    scope = orderScopeEnum.PERSONAL,
    sort = 'newest-first',
    searchPageNumber = 1,
    specialty,
    dataSource = '',
    motivation = '',
  ) => {
    if (search_query === undefined && guid === undefined) {
      redirectToDashboard();
    } else {
      setOrderPageNumber(searchPageNumber);
      dispatch(
        setSearchQueryText({
          searchText: search_query,
          pageNumber: searchPageNumber,
          advancedSearchValue: searchData?.advancedSearchValue,
          isSearchFromHome: false,
        }),
      );
      const token = await getIdTokenClaims();
      dispatch(
        getSearchData({
          params: {
            simple_search: {
              text: search_query,
            },
            scope: scope,
            sort: sort,
            size: ORDER_PAGINATION_SIZE,
            page: searchPageNumber,
            advance_search: {},
            specialty,
            sort_date_type: scope === 'personal' ? 'order_date' : 'order_completed_date',
            datasource: dataSource,
            motivation: motivation,
          },
          orderIndexData: orderIndexData ? orderIndexData : '',
          token: getRawToken(token),
          advancedSearchValue: searchData?.advancedSearchValue,
        }),
      );
    }
  };

  const searchOrdersByType = (index) => {
    setSpecialtyTag('');
    setDataSourceTag('');
    setMotivationTag('');
    setTabIndex(index);
    setOrderIndex(null);
    let scope = '';
    switch (index) {
      case 0:
      default:
        scope = orderScopeEnum.PERSONAL;
        setOrderScope(scope);
        break;
      case 1:
        scope = orderScopeEnum.LIBRARY;
        setOrderScope(scope);
        break;
    }
    redirectToSearchResult(index);
    mixpanel.track('Tab clicked', {
      selected_tab_value: scope,
    });
    dispatch(setOrderScopeData({ scopeTabIndex: index, orderScope: scope }));
    searchOrder(searchData?.searchText, scope, sortOrderBy, 1, specialtyTag);
    mixpanel.track('Screen path changed event', {
      screen_path: window.location.pathname,
    });
  };

  const handleSearchClick = () => {
    setOrderIndex(null);
  };

  const returnBgColor = (question_type) => {
    var bgColor = '';
    if (questionData) {
      for (let i = 0; i < questionData?.length; i++) {
        if (questionData[i].name === question_type) {
          bgColor = questionData[i].style.backgroundColor;
        }
      }
    }
    return bgColor;
  };

  return (
    <div className="home-container">
      <div>
        <CustomModal
          isOpen={showModal && !isOrderLoading}
          handleClose={OnClickOfClose}
          cssClass="white-overlay"
          modalname="Order cancel modal"
          overlayCssClass={'cancel-modal-overlay'}
        >
          <div
            ref={ref}
            className={`email-modal px-5 py-3 delete-modal text-center color-white d-flex flex-justify-between ${
              !!deleteOrderError ? 'bg-error' : 'bg-delete-success'
            }`}
          >
            {!!deleteOrderError ? (
              <div className="small">{deleteOrderError}</div>
            ) : (
              <div className="small">Prognostogram was cancelled successfully.</div>
            )}
            <ButtonCustom
              cssClass="close-btn color-white"
              onClick={() => OnClickOfClose()}
              buttonAction={'Modal closed button clicked'}
              id={'btn-modal-closed-button'}
            >
              <VscChromeClose />
            </ButtonCustom>
          </div>
        </CustomModal>
        <CustomModal
          isOpen={isDisplayOrder}
          handleClose={() => OnClickOfOrderClose(singleOrderData?.is_owner)}
          cssClass="white-overlay"
          modalname="Order modal"
          overlayCssClass={'order-modal-overlay'}
        >
          <div ref={ref} className={`email-modal p-3 order-modal`}>
            {isgettingOrderDetails ? (
              <div className="position-relative">
                <AuthLoader fullScreen={false} />
              </div>
            ) : (
              <>
                {singleOrderData && Object.keys(singleOrderData).length !== 0 ? (
                  <div className="single-prognostorm">
                    <div className="title mt-1 mb-3 pr-6 pr-md-6" id="order-title-0">
                      {singleOrderData.picot_status === true
                        ? singleOrderData.question_title
                          ? singleOrderData.question_title
                          : singleOrderData.clinical_questions
                        : singleOrderData.clinical_questions}
                    </div>
                    <PrognostorgramOrderDetails
                      uuid={singleOrderData.case_uuid ? singleOrderData.case_uuid : ''}
                      email={
                        singleOrderData.email_address ? singleOrderData.email_address : ''
                      }
                      date={
                        tabIndex === 0
                          ? dateFormat(singleOrderData.order_date)
                          : dateFormat(singleOrderData.order_completed_date)
                      }
                      tag={singleOrderData.question_type}
                      uniqueKey={`order--${singleOrderData.modified_date}`}
                      color={returnBgColor(singleOrderData.question_type)}
                      questionStatus={singleOrderData.status}
                      tabIndex={tabIndex}
                      filterBySpecialty={() =>
                        getFirstPageOrdersBySpecialty(singleOrderData.provider_specialty)
                      }
                      filterByStatus={() =>
                        getFirstPageOrdersByStatus(singleOrderData.status)
                      }
                      filterByDataSet={() =>
                        getFirstPageOrdersByDataSet(singleOrderData.data_source)
                      }
                      filterByMotivation={getFirstPageOrdersByMotivation}
                      specialtyTag={singleOrderData.provider_specialty}
                      dataSource={singleOrderData.data_source}
                      motivations={singleOrderData.motivations}
                    />
                    <PicotOptions
                      orderGuid={singleOrderData.guid}
                      population={singleOrderData.population}
                      intervention={singleOrderData.intervention}
                      control={singleOrderData.control}
                      outcome={singleOrderData.outcome}
                      timeframe={singleOrderData.timeframe}
                      onModify={() => modifyOrder(singleOrderData, 0)}
                      onEdit={() => editOrder(singleOrderData, 0)}
                      questionStatus={singleOrderData.status}
                      onCancelOrder={() => cancelOrder(singleOrderData.guid)}
                      tabIndex={tabIndex}
                      pdfFile={pdfFile}
                      conclusion={singleOrderData.conclusion}
                      summary={singleOrderData.summary}
                    />
                  </div>
                ) : (
                  ''
                )}
                {getOrderDetailError && (
                  <div className="d-flex error-container my-4">
                    <div className="mr-3 flex-self-center">
                      <RiErrorWarningFill className="d-block" />
                    </div>
                    <div className="flex-self-center">
                      Oops! It looks like you do not have access to that Prognostogram. If
                      you think this is an error, please reach out to our team at{' '}
                      <a href="mailto:support@atroposhealth.com">
                        Support@atroposhealth.com
                      </a>
                    </div>
                  </div>
                )}

                <ButtonCustom
                  cssClass="close-btn color-white"
                  onClick={() => OnClickOfOrderClose(singleOrderData?.is_owner)}
                  buttonAction={'Modal closed button clicked'}
                  id={'btn-modal-closed-button'}
                >
                  <VscChromeClose />
                </ButtonCustom>
              </>
            )}
          </div>
        </CustomModal>
        <div className="my-md-7">
          <Tabs
            selectedTabClassName="active-tab gradient-btn "
            className="custom-tabs"
            onSelect={(index) => searchOrdersByType(index)}
            defaultIndex={tabIndex}
          >
            <div className="d-flex flex-md-justify-between mb-4 flex-md-row flex-column-reverse">
              <TabList className="d-flex custom-tablist">
                <Tab id={'tab-personal'}>Personal</Tab>
                <Tab id={'tab-library'}>Library</Tab>
              </TabList>
              <ButtonSecondary
                label="Order a Prognostogram"
                cssClass="order-btn my-4 my-md-0"
                onClick={handleClick}
                buttonAction="Order a prognostogram button clicked"
                id={'btn-order-prognostogram-button'}
              />
            </div>
            <SearchForm handleSearchClick={handleSearchClick} />
            <DashboardHeader
              setNewStatusFilter={getFirstPageOrders}
              tabIndex={tabIndex}
              isShowStats={false}
              searchOrderCount={count ? count : 0}
              searchText={searchData?.searchText ? searchData.searchText : ''}
              hideLibraryMessage={isSearchOrderLoading && orderPageNumber === 1}
              specialty={specialtyTag}
              dataSource={dataSourceTag}
              motivation={motivationTag}
            />
            <TabPanel>{displayOrder()}</TabPanel>
            <TabPanel>{displayOrder()}</TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
export default SearchResult;
