import React from 'react';
import CustomModal from './CustomModal';
import { ButtonCustom } from 'components/form-input/Button';
import { VscChromeClose } from 'react-icons/vsc';

const SessionTimeoutNotificationModal = ({
  showModal,
  OnClickOfClose,
  OnClickOfLogin,
  OnClickOfLogout,
}) => {
  return (
    <CustomModal
      isOpen={showModal}
      handleClose={OnClickOfClose}
      cssClass="white-overlay"
      modalname="Session timeout modal"
      overlayCssClass={'cancel-modal-overlay'}
    >
      <div className="email-modal p-5 delete-modal text-center">
        <div className="profile-sub-header mt-4 mb-2">Your session is about to expire</div>
        <div className="cancel-sub-header mt-4">Due to inactivity, you will be logged out in 30 seconds.</div>
        <div className="d-flex flex-justify-between">
          <ButtonCustom
            cssClass="plain-btn mt-5"
            onClick={OnClickOfLogout}
            buttonAction={'Logout button clicked'}
            actionLabel={'User loggout'}
            id={'btn-sessionn-logout-button'}
          >
            Logout
          </ButtonCustom>
          <ButtonCustom
            cssClass="btn-primary mt-5 px-4"
            onClick={() => OnClickOfLogin()}
            buttonAction={'Stay connected button clicked'}
            actionLabel={'Session timeout Stay connected button'}
            id={'btn-session-stay-connected-button'}
          >
            Stay connected
          </ButtonCustom>
        </div>
      </div>
    </CustomModal>
  );
};

export default SessionTimeoutNotificationModal;
