import { all } from 'redux-saga/effects';
import { sagas as userProfile } from './modules/userProfile/saga';
import { sagas as orderDetails } from './modules/orderDetails/saga';
import { sagas as searchOrder } from './modules/searchOrder/saga';
import { sagas as userSubscription } from './modules/userSubscription/saga';

export default function* rootSaga() {
  yield all(
    [...userProfile, ...orderDetails, ...searchOrder, ...userSubscription].map((func) => func()).concat(),
  );
}
