import { combineReducers } from 'redux';
import userProfile from './userProfile/reducer';
import orderDetails from './orderDetails/reducer';
import searchOrder from './searchOrder/reducer';
import userSubscription from './userSubscription/reducer';

export default combineReducers({
  userProfile,
  orderDetails,
  searchOrder,
  userSubscription,
});
