import React, { useState, useRef, useEffect } from 'react';
import { ButtonCustom, ButtonLink } from 'components/form-input/Button';
import { HiOutlineSelector, HiOutlineChevronDown } from 'react-icons/hi';
import statusHeaderEnum from '../constants/enum/statusHeaderEnum';
import sortOptionEnum from 'constants/enum/sortOptionEnum';
import { useSelector } from 'react-redux';
import Checkbox from 'components/form-input/Checkbox';
import RadioButton from 'components/form-input/RadioButton';
import { useMixpanel } from 'react-mixpanel-browser';
import { RiFilter3Fill } from 'react-icons/ri';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import crossIcon from '../assets/images/cross.png';

const DashboardHeader = ({
  setNewStatusFilter,
  orderStatusCountData,
  tabIndex,
  orderListCount = 0,
  isShowStats = true,
  searchOrderCount = 0,
  searchText,
  hideLibraryMessage = false,
  status,
  specialty,
  dataSource,
  motivation,
}) => {
  const [orderStatus, setOrderStatus] = useState(statusHeaderEnum.ALL);
  const [sortBy, setSortBy] = useState('newest-first');
  const [isShowContert, setShowContert] = useState(false);
  const innerRef = useRef();
  const specialtyRef = useRef();
  const dataSetRef = useRef();
  const providerMotivationRef = useRef();
  const [isDropDownFlag, setDropDownCard] = useState(false);
  const [isCloseOpenedOrder, setCloseOpenedOrder] = useState(false);
  const [isSpecialtyDropdown, setIsSpecialtyDropdown] = useState(false);
  const [isDataSetDropdown, setIsDataSetDropdown] = useState(false);
  const [isProviderMotivationDropdown, setIsProviderMotivationDropdown] = useState(false);
  const [newSpecialtyValue, setNewSpecialtyValue] = useState('');
  const [newDataSourceValue, setNewDataSetValue] = useState('');
  const [newProviderMotivationValue, setNewProviderMotivationValue] = useState('');
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [specialtyCount, setSpecialtyCount] = useState(0);
  const [dataSourceCount, setDataSourceCount] = useState(0);
  const [motivationCount, setMotivationCount] = useState(0);
  const [statusFilterValue, setStatusFilterValue] = useState(statusHeaderEnum.ALL);
  const [isSpecialtyDropdownMobile, setIsSpecialtyDropdownMobile] = useState(false);
  const [isStatusDropdownMobile, setIsStatusDropdownMobile] = useState(false);
  const [isDataSetDropdownMobile, setIsDataSetDropdownMobile] = useState(false);
  const [isProviderMotivationDropdownMobile, setIsProviderMotivationDropdownMobile] =
    useState(false);
  const { specialtyData, isSearchOrderLoading, isOrderLoading } = useSelector(({ orderDetails }) => orderDetails);
  const datasources = specialtyData ? specialtyData[0]?.datasources : '';
  const specialties = specialtyData ? specialtyData[0]?.specialties : '';
  const motivations = specialtyData ? specialtyData[0]?.motivations : '';
  const setStatusAndFilterOrder = (status) => {
    if (status !== orderStatus) {
      setCloseOpenedOrder(true);
    }
    setOrderStatus(status);
  };
  const mixpanel = useMixpanel();

  useEffect(() => {
    if(status){
      setOrderStatus(status);
    }
  }, [status]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropDownFlag && !innerRef.current?.contains(e.target)) {
        setDropDownCard(false);
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [isDropDownFlag]);

  useEffect(() => {
    function handler(event) {
      if (isSpecialtyDropdown && !specialtyRef.current?.contains(event.target)) {
        setIsSpecialtyDropdown(false);
      }
      if (isDataSetDropdown && !dataSetRef.current?.contains(event.target)) {
        setIsDataSetDropdown(false);
      }
      if (
        isProviderMotivationDropdown &&
        !providerMotivationRef.current?.contains(event.target)
      ) {
        setIsProviderMotivationDropdown(false);
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [isSpecialtyDropdown, isDataSetDropdown, isProviderMotivationDropdown]);

  const handleStatusFilterChange = (e) => {
    setStatusFilterValue(e.target.value);
    setOrderStatus(e.target.value);
  };
  const handleFilterChange = (e, filter) => {
    var values = '';
    var data = '';
    if (filter === 'Specialty') {
      data = specialties;
      values = newSpecialtyValue !== '' ? newSpecialtyValue.split(',') : [];
    } else if (filter === 'DataSource') {
      data = datasources;
      values = newDataSourceValue !== '' ? newDataSourceValue.split(',') : [];
    } else {
      data = motivations;
      values =
        newProviderMotivationValue !== '' ? newProviderMotivationValue.split(',') : [];
    }
    data?.map((filter) => {
      if (e.target.value === filter.name) {
        if (e.target.checked) {
          values.push(e.target.value);
        } else {
          var index = values.indexOf(e.target.value);
          if (index > -1) {
            values.splice(index, 1);
          }
        }
      }
    });
    mixpanel.track('Specialty clicked', {
      specailty_value: values.join(),
    });
    if (filter === 'Specialty') {
      setNewSpecialtyValue(values.join());
    } else if (filter === 'DataSource') {
      setNewDataSetValue(values.join());
    } else {
      setNewProviderMotivationValue(values.join());
    }
  };

  useEffect(() => {
    setSpecialtyCount(newSpecialtyValue !== '' ? newSpecialtyValue.split(',').length : 0);
    setDataSourceCount(
      newDataSourceValue !== '' ? newDataSourceValue.split(',').length : 0,
    );
    setMotivationCount(
      newProviderMotivationValue !== ''
        ? newProviderMotivationValue.split(',').length
        : 0,
    );
  }, [newSpecialtyValue, newDataSourceValue, newProviderMotivationValue]);

  const setFilterChecked = (value, filter) => {
    if (filter === 'Specialty') {
      if (specialty && value === specialty) {
        return true;
      } else {
        return checkFilterValue(value, newSpecialtyValue);
      }
    } else if (filter === 'DataSource') {
      if (dataSource && value === dataSource) {
        return true;
      } else {
        return checkFilterValue(value, newDataSourceValue);
      }
    } else {
      if (motivation && value === motivation) {
        return true;
      } else {
        return checkFilterValue(value, newProviderMotivationValue);
      }
    }
  };

  const checkFilterValue = (value, newFilterValue) => {
    if (newFilterValue) {
      var filterValues = newFilterValue.split(',');
      var index = filterValues.indexOf(value);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getStatusFilterList = () => {
    return (
      <ul>
        <li className="filter-option p-4 mt-3">
          <RadioButton
            buttonName={`All (${
              orderStatusCountData?.all ? orderStatusCountData?.all : 0
            })`}
            onClick={(e) => handleStatusFilterChange(e)}
            name={'status'}
            value={statusHeaderEnum.ALL}
            checked={statusFilterValue === statusHeaderEnum.ALL ? true : false}
            id={'btn-mobile-status-all-button'}
          />
        </li>
        <li className="filter-option p-4 mt-3">
          <RadioButton
            buttonName={`Pending (${
              orderStatusCountData?.pending ? orderStatusCountData?.pending : 0
            })`}
            onClick={(e) => handleStatusFilterChange(e)}
            name={'status'}
            value={statusHeaderEnum.PENDING}
            checked={statusFilterValue === statusHeaderEnum.PENDING ? true : false}
            id={'btn-mobile-status-pending-button'}
          />
        </li>
        <li className="filter-option p-4 mt-3">
          <RadioButton
            buttonName={`Ongoing (${
              orderStatusCountData?.in_progress ? orderStatusCountData?.in_progress : 0
            })`}
            onClick={(e) => handleStatusFilterChange(e)}
            name={'status'}
            value={statusHeaderEnum.INPROGRESS}
            checked={statusFilterValue === statusHeaderEnum.INPROGRESS ? true : false}
            id={'btn-mobile-status-inprogress-button'}
          />
        </li>
        <li className="filter-option p-4 mt-3">
          <RadioButton
            buttonName={`Triaged (${
              orderStatusCountData?.triage ? orderStatusCountData?.triage : 0
            })`}
            onClick={(e) => handleStatusFilterChange(e)}
            name={'status'}
            value={statusHeaderEnum.TRIAGE}
            checked={statusFilterValue === statusHeaderEnum.TRIAGE ? true : false}
            id={'btn-mobile-status-triage-button'}
          />
        </li>
        <li className="filter-option p-4 mt-3">
          <RadioButton
            buttonName={`Completed (${
              orderStatusCountData?.completed ? orderStatusCountData?.completed : 0
            })`}
            onClick={(e) => handleStatusFilterChange(e)}
            name={'status'}
            value={statusHeaderEnum.COMPLETED}
            checked={statusFilterValue === statusHeaderEnum.COMPLETED ? true : false}
            id={'btn-mobile-status-completed-button'}
          />
        </li>
      </ul>
    );
  };
  const getFilterList = (list, filter) => {
    if (list) {
      var classNameForLi = isShowFilters
        ? 'filter-option p-4 mt-3'
        : 'd-flex flex-content-center my-3 color-black li-list-specialty';
      var classNameForUi = isShowFilters ? '' : 'specialty-list';
      const listItems = list?.map((filterValue, index) => (
        <li key={index + '-' + filterValue.name} className={classNameForLi + `${filterValue.count === 0 ? ' disabled-filter' : ''}`}>
          <Checkbox
            buttonName={filterValue.name + ' (' + filterValue.count + ')'}
            onChange={(e) => handleFilterChange(e, filter)}
            name={filter}
            value={filterValue.name}
            checked={setFilterChecked(filterValue.name, filter)}
            id={`checkbox-mobile-filter${filter}-${filterValue.name}`}
            disabled={filterValue.count === 0 ? true : false}
          />
        </li>
      ));
      return <ul className={classNameForUi}>{listItems}</ul>;
    } else {
      return '';
    }
  };

  const sortOrderBy = (str) => {
    if (str == sortOptionEnum.OLDEST_FIRST) {
      setSortBy(sortOptionEnum.OLDEST_FIRST);
    } else if (str == sortOptionEnum.NEWEST_FIRST) {
      setSortBy(sortOptionEnum.NEWEST_FIRST);
    } else if (str == sortOptionEnum.ALPHABETICAL_A_TO_Z) {
      setSortBy(sortOptionEnum.ALPHABETICAL_A_TO_Z);
    } else if (str == sortOptionEnum.ALPHABETICAL_Z_TO_A) {
      setSortBy(sortOptionEnum.ALPHABETICAL_Z_TO_A);
    } else {
      setSortBy(sortOptionEnum.NEWEST_FIRST);
    }
    mixpanel.track('Sort clicked event', {
      selected_sort_option: str,
    });
    setDropDownCard(false);
  };

  const setFlagDropDownCard = () => {
    setDropDownCard(true);
  };

  const onClickOfSort = () => {
    mixpanel.track('Sort clicked');
    setFlagDropDownCard();
  };

  useEffect(() => {
    if (!isShowFilters) {
      setNewStatusFilter(
        orderStatus,
        sortBy,
        newSpecialtyValue,
        newDataSourceValue,
        newProviderMotivationValue,
        isCloseOpenedOrder,
      );
    }
  }, [
    sortBy,
    orderStatus,
    newSpecialtyValue,
    newDataSourceValue,
    newProviderMotivationValue,
  ]);

  useEffect(() => {
    if (specialty) {
      setNewSpecialtyValue(specialty);
    }
    if (dataSource) {
      setNewDataSetValue(dataSource);
    }
    if (motivation) {
      setNewProviderMotivationValue(motivation);
    }
  }, [specialty, dataSource, motivation]);

  useEffect(() => {
    setNewSpecialtyValue('');
    setNewDataSetValue('');
    setNewProviderMotivationValue('');
  }, [tabIndex]);

  const showSearchText = () => {
    if (
      searchText &&
      (newSpecialtyValue || newDataSourceValue || newProviderMotivationValue)
    ) {
      var text = newSpecialtyValue ? newSpecialtyValue : '';
      text += newDataSourceValue
        ? text
          ? ', ' + newDataSourceValue
          : '' + newDataSourceValue
        : '';
      text += newProviderMotivationValue
        ? text
          ? ', ' + newProviderMotivationValue
          : '' + newProviderMotivationValue
        : '';
      return searchText.split(',').join(', ') + ', ' + text.split(',').join(', ');;
    } else if (searchText) {
      return searchText.split(',').join(', ');
    } else if (newSpecialtyValue || newDataSourceValue || newProviderMotivationValue) {
      var text = newSpecialtyValue ? newSpecialtyValue : '';
      text += newDataSourceValue
        ? text
          ? ', ' + newDataSourceValue
          : '' + newDataSourceValue
        : '';
      text += newProviderMotivationValue
        ? text
          ? ', ' + newProviderMotivationValue
          : '' + newProviderMotivationValue
        : '';
      return text.split(',').join(', ');
    } else {
      return '';
    }
  };

  const showSearchCount = () => {
    if (searchText) {
      return searchOrderCount ? searchOrderCount : 0;
    } else if (
      newSpecialtyValue ||
      newDataSourceValue ||
      newProviderMotivationValue ||
      statusFilterValue
    ) {
      return orderListCount ? orderListCount : 0;
    } else {
      return 0;
    }
  };

  const isCountPresent = () => {
    if (orderStatusCountData) {
      if (
        orderStatusCountData?.all !== 0 ||
        orderStatusCountData?.in_progress !== 0 ||
        orderStatusCountData?.completed !== 0 ||
        orderStatusCountData?.pending !== 0
      ) {
        setShowContert(true);
      } else {
        setShowContert(false);
      }
    } else {
      setShowContert(false);
    }
  };

  const onClickOfCloseFilter = () => {
    setIsShowFilters(false);
    setIsSpecialtyDropdownMobile(false);
    setIsDataSetDropdownMobile(false);
    setIsProviderMotivationDropdownMobile(false);
    setIsStatusDropdownMobile(false);
    document.body.classList.remove('overflow-hidden');
    document.body.classList.remove('hide-chat');
  };

  const filterOrders = (
    specialtyValue,
    dataSetValue,
    motivationValue,
    statusFilterValue,
  ) => {
    setNewStatusFilter(
      statusFilterValue,
      sortBy,
      specialtyValue,
      dataSetValue,
      motivationValue,
      isCloseOpenedOrder,
    );
  };

  const handleCloseFilter = () => {
    !!onClickOfCloseFilter && onClickOfCloseFilter();
    setNewSpecialtyValue('');
    setNewDataSetValue('');
    setNewProviderMotivationValue('');
    setStatusFilterValue(statusHeaderEnum.ALL);
    setOrderStatus(statusHeaderEnum.ALL);
    filterOrders('', '', '', statusHeaderEnum.ALL);
  };
  const countFilter = () => {
    setIsSpecialtyDropdownMobile(false);
    setIsDataSetDropdownMobile(false);
    setIsProviderMotivationDropdownMobile(false);
    setIsStatusDropdownMobile(false);
  };

  const showFilterContent = () => {
    if (
      !(
        !isSpecialtyDropdownMobile &&
        !isDataSetDropdownMobile &&
        !isProviderMotivationDropdownMobile &&
        !isStatusDropdownMobile
      )
    ) {
      var text = '';
      if (isSpecialtyDropdownMobile) {
        text = 'Specialty';
      } else if (isDataSetDropdownMobile) {
        text = 'Dataset';
      } else if (isProviderMotivationDropdownMobile) {
        text = 'Motivation';
      } else {
        text = 'Status';
      }
      return (
        <div className="d-flex flex-items-center">
          <ButtonCustom
            cssClass="filter-back-btn mr-2 flex-self-center d-flex"
            onClick={() => countFilter()}
            buttonAction={'Filter back button clicked'}
            id={'btn-mobile-filter-back-button'}
          >
            <HiOutlineArrowLeft />
          </ButtonCustom>
          <span className="d-block text-left">{text}</span>
        </div>
      );
    } else {
      var count = 0;
      if (tabIndex === 0) {
        count = dataSourceCount + (statusFilterValue !== '' ? 1 : 0);
      } else {
        count = specialtyCount + dataSourceCount + motivationCount;
      }
      return `Filter ${count !== 0 ? '(' + count + ')' : ''}`;
    }
  };
  const handleFilterOrders = () => {
    if (statusFilterValue === '') {
      setStatusFilterValue(statusHeaderEnum.ALL);
    }
    onClickOfCloseFilter();
    document.body.classList.remove('hide-chat');
    filterOrders(
      newSpecialtyValue,
      newDataSourceValue,
      newProviderMotivationValue,
      statusFilterValue,
    );
  };

  const handleClear = () => {
    if (isSpecialtyDropdownMobile) {
      setNewSpecialtyValue('');
      filterOrders('', newDataSourceValue, newProviderMotivationValue, statusFilterValue);
    } else if (isDataSetDropdownMobile) {
      setNewDataSetValue('');
      filterOrders(newSpecialtyValue, '', newProviderMotivationValue, statusFilterValue);
    } else if (isProviderMotivationDropdownMobile) {
      setNewProviderMotivationValue('');
      filterOrders(newSpecialtyValue, newDataSourceValue, '', statusFilterValue);
    } else if (isStatusDropdownMobile) {
      setStatusFilterValue('');
      setOrderStatus(statusHeaderEnum.ALL);
      filterOrders(
        newSpecialtyValue,
        newDataSourceValue,
        newProviderMotivationValue,
        statusHeaderEnum.ALL,
      );
    } else {
      setOrderStatus(statusHeaderEnum.ALL);
      setStatusFilterValue('');
      setNewSpecialtyValue('');
      setNewDataSetValue('');
      setNewProviderMotivationValue('');
      filterOrders('', '', '', statusHeaderEnum.ALL);
    }
  };
  const showMobileFilters = () => {
    return (
      <div className="filter-outer-container d-md-none d-block">
        <div className="search-overlay"></div>
        <ButtonCustom
          cssClass="filter-close-btn"
          onClick={() => handleCloseFilter()}
          buttonAction={'Mobile filter closed button clicked'}
          id={'btn-mobile-filter-close-button'}
        >
          <img src={crossIcon} alt="Close" />
        </ButtonCustom>
        <div className="grey-container">
          <div className="filter-containter-1 position-relative">
            <div className="filter-header d-flex flex-justify-between p-4 flex-items-center">
              <div>{showFilterContent()}</div>
              <ButtonCustom
                cssClass=""
                onClick={() => handleClear()}
                buttonAction={'Mobile filter clear button clicked'}
                id={'btn-mobile-filter-clear-button'}
              >
                Clear
              </ButtonCustom>
            </div>
            {!isSpecialtyDropdownMobile &&
            !isDataSetDropdownMobile &&
            !isProviderMotivationDropdownMobile &&
            !isStatusDropdownMobile ? (
              <div className="px-3 filter-list-conatiner ">
                {tabIndex === 1 ? (
                  <ButtonCustom
                    cssClass="filter-btn p-4 mt-3"
                    onClick={() =>
                      setIsSpecialtyDropdownMobile(!isSpecialtyDropdownMobile)
                    }
                    buttonAction={'Mobile specialty clicked'}
                    id={'btn-mobile-filter-specialty'}
                  >
                    Specialty {specialtyCount !== 0 ? ` (${specialtyCount})` : ''}
                  </ButtonCustom>
                ) : (
                  ''
                )}
                {tabIndex === 0 ? (
                  <ButtonCustom
                    cssClass="filter-btn p-4 mt-3"
                    onClick={() => setIsStatusDropdownMobile(!isStatusDropdownMobile)}
                    buttonAction={'Mobile status clicked'}
                    id={'btn-mobile-filter-status'}
                  >
                    Status {statusFilterValue !== '' ? ' (1)' : ''}
                  </ButtonCustom>
                ) : (
                  ''
                )}
                <ButtonCustom
                  cssClass="filter-btn p-4 mt-3"
                  onClick={() => setIsDataSetDropdownMobile(!isDataSetDropdownMobile)}
                  buttonAction={'Mobile dataset clicked'}
                  id={'btn-mobile-filter-dataset'}
                >
                  Dataset {dataSourceCount !== 0 ? ` (${dataSourceCount})` : ''}
                </ButtonCustom>
                {tabIndex === 1 ? (
                  <ButtonCustom
                    cssClass="filter-btn p-4 mt-3 mb-3"
                    onClick={() =>
                      setIsProviderMotivationDropdownMobile(
                        !isProviderMotivationDropdownMobile,
                      )
                    }
                    buttonAction={'Mobile provider motivation clicked'}
                    id={'btn-mobile-filter-provider-motivation'}
                  >
                    Motivation{' '}
                    {motivationCount !== 0 ? ` (${motivationCount})` : ''}
                  </ButtonCustom>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            {isStatusDropdownMobile ? (
              <div className="filter-list-conatiner px-3">{getStatusFilterList()}</div>
            ) : (
              ''
            )}
            {isSpecialtyDropdownMobile && specialties ? (
              <div className="filter-list-conatiner px-3">
                {getFilterList(specialties, 'Specialty')}
              </div>
            ) : (
              ''
            )}
            {isDataSetDropdownMobile && datasources ? (
              <div className="filter-list-conatiner px-3">
                {getFilterList(datasources, 'DataSource')}
              </div>
            ) : (
              ''
            )}
            {isProviderMotivationDropdownMobile && motivations ? (
              <div className="filter-list-conatiner px-3">
                {getFilterList(motivations, 'ProviderMotivation')}
              </div>
            ) : (
              ''
            )}
            <div className="filter-footer p-2">
              <ButtonCustom
                cssClass="p-4 filter-view-btn"
                disabled={
                  newSpecialtyValue === '' &&
                  newDataSourceValue === '' &&
                  newProviderMotivationValue === '' &&
                  statusFilterValue === ''
                }
                onClick={() => handleFilterOrders()}
                buttonAction={'Mobile view results button clicked'}
                id={'btn-mobile-filter-view-results-button'}
              >
                View Results
              </ButtonCustom>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    isCountPresent();
  }, [orderStatusCountData, orderListCount]);

  const handleClickOfClearFilter = () => {
    setCloseOpenedOrder(true);
    setNewSpecialtyValue('');
    setNewDataSetValue('');
    setNewProviderMotivationValue('');
    setStatusFilterValue(statusHeaderEnum.ALL);
    setOrderStatus(statusHeaderEnum.ALL);
    filterOrders('', '', '', statusHeaderEnum.ALL);
  };

  return (
    <>
      <div className={`d-md-flex flex-justify-between ${(!newDataSourceValue && tabIndex === 0 && searchText === '') ? 'd-md-none' : ''}`}>
        {tabIndex === 1 &&
        orderListCount !== 0 &&
        isShowStats &&
        !hideLibraryMessage &&
        !newSpecialtyValue &&
        !newDataSourceValue &&
        !newProviderMotivationValue ? (
          <div className="flex-self-center mt-4">
            You are currently browsing {orderListCount} completed Prognostograms.
          </div>
        ) : (
          ''
        )}
        {(searchText && !hideLibraryMessage) ? (
          <div className="flex-self-center pr-2 mt-4 text-left search-result-text">
            Your search for <span className="search-query">{showSearchText()}</span>{' '}
            returned {showSearchCount()} results.{' '}
            {(newSpecialtyValue || newDataSourceValue || newProviderMotivationValue) &&
              !hideLibraryMessage && (
                <ButtonCustom
                  cssClass="clear-filter-btn"
                  onClick={() => {
                    handleClickOfClearFilter();
                  }}
                  buttonAction={'Clear filters button clicked'}
                  id={'btn-clear-filters-button'}
                >
                  Clear Filters
                </ButtonCustom>
              )}
          </div>
        ) : (
          <div
            className={`${
              ((!newSpecialtyValue ||
                !newDataSourceValue ||
                !newProviderMotivationValue) &&
                tabIndex === 1) ||
              (newDataSourceValue && tabIndex === 0)
                ? 'mt-4'
                : ''
            } visibility-hidden d-md-block d-none`}
          >
            Test
          </div>
        )}
      </div>
      {tabIndex === 0 && isShowStats && isShowContert ? (
        <div
          className={`dashboard-header-container d-md-flex mt-4 mb-0 d-none ${
            tabIndex === 1 && !searchText && isShowContert
              ? 'flex-justify-between'
              : 'flex-justify-between'
          } my-md-4`}
        >
          <div className="d-md-flex">
            <div
              className={`status-container d-md-block d-flex flex-items-center my-md-0 my-2 flex-justify-between${
                orderStatus === statusHeaderEnum.ALL ? ' active-header' : ''
              }`}
            >
              <ButtonCustom
                cssClass="header-btn"
                onClick={() => setStatusAndFilterOrder(statusHeaderEnum.ALL)}
                buttonAction={'Order status filter clicked'}
                actionValue={statusHeaderEnum.ALL}
                id={'btn-desktop-status-all-button'}
              >
                All{' '}
                <span>{orderStatusCountData?.all ? orderStatusCountData?.all : 0}</span>
              </ButtonCustom>
            </div>
            <div
              className={`status-container d-md-block d-flex flex-items-center my-md-0 my-2 flex-justify-between${
                orderStatus === statusHeaderEnum.PENDING ? ' active-header' : ''
              }`}
            >
              <ButtonCustom
                cssClass="header-btn"
                onClick={() => setStatusAndFilterOrder(statusHeaderEnum.PENDING)}
                buttonAction={'Order status filter clicked'}
                actionValue={statusHeaderEnum.PENDING}
                id={'btn-desktop-status-pending-button'}
              >
                Pending{' '}
                <span>
                  {orderStatusCountData?.pending ? orderStatusCountData?.pending : 0}
                </span>
              </ButtonCustom>
            </div>
            <div
              className={`status-container d-md-block d-flex flex-items-center my-md-0 my-2 flex-justify-between${
                orderStatus === statusHeaderEnum.INPROGRESS ? ' active-header' : ''
              }`}
            >
              <ButtonCustom
                cssClass="header-btn"
                onClick={() => setStatusAndFilterOrder(statusHeaderEnum.INPROGRESS)}
                buttonAction={'Order status filter clicked'}
                actionValue={statusHeaderEnum.INPROGRESS}
                id={'btn-desktop-status-inprogress-button'}
              >
                Ongoing{' '}
                <span>
                  {orderStatusCountData?.in_progress
                    ? orderStatusCountData?.in_progress
                    : 0}
                </span>
              </ButtonCustom>
            </div>
            <div
              className={`status-container d-md-block d-flex flex-items-center my-md-0 my-2 flex-justify-between${
                orderStatus === statusHeaderEnum.TRIAGE ? ' active-header' : ''
              }`}
            >
              <ButtonCustom
                cssClass="header-btn"
                onClick={() => setStatusAndFilterOrder(statusHeaderEnum.TRIAGE)}
                buttonAction={'Order status filter clicked'}
                actionValue={statusHeaderEnum.TRIAGE}
                id={'btn-desktop-status-triage-button'}
              >
                Triaged{' '}
                <span>
                  {orderStatusCountData?.triage ? orderStatusCountData?.triage : 0}
                </span>
              </ButtonCustom>
            </div>
            <div
              className={`status-container d-md-block d-flex flex-items-center my-md-0 my-2 flex-justify-between${
                orderStatus === statusHeaderEnum.COMPLETED ? ' active-header' : ''
              }`}
            >
              <ButtonCustom
                cssClass="header-btn"
                onClick={() => setStatusAndFilterOrder(statusHeaderEnum.COMPLETED)}
                buttonAction={'Order status filter clicked'}
                actionValue={statusHeaderEnum.COMPLETED}
                id={'btn-desktop-status-completed-button'}
              >
                Completed{' '}
                <span>
                  {orderStatusCountData?.completed ? orderStatusCountData?.completed : 0}
                </span>
              </ButtonCustom>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div
        className={`dashboard-header-container d-md-flex mt-4 mt-md-4 mb-0 ${
          tabIndex === 1 && !searchText && isShowContert
            ? 'flex-justify-between'
            : 'flex-justify-between'
        } my-md-4`}
      >
        {isShowFilters ? <>{showMobileFilters()}</> : ''}
        <div className="filter-div d-md-flex flex-justify-between d-none">
          {tabIndex !== 0 && specialties ? (
            <div className={`specialty-shared-div mr-0 mr-md-3 col-md-4 ${(isSearchOrderLoading || isOrderLoading) && 'disabled'}`}>
              <div
                className={`specialty-shared mr-5 ${(isSearchOrderLoading || isOrderLoading) && 'pointer-events-none'}`}
                onClick={() => setIsSpecialtyDropdown(!isSpecialtyDropdown)}
              >
                <div className="specialty-title">
                  {newSpecialtyValue === '' ? 'Specialty' : newSpecialtyValue.split(',').join(', ')}
                </div>
                <div className="specialty-down-arrow">
                  <ButtonLink
                    buttonAction="Specialty dropdown clicked"
                    id={'btn-desktop-filters-specialty'}
                  >
                    <HiOutlineChevronDown />
                  </ButtonLink>
                </div>
              </div>
              {isSpecialtyDropdown && specialties ? (
                <div className="specialty-container" ref={specialtyRef}>
                  <div className="specialty-div">
                    <div className="specialty-body-div">
                      {getFilterList(specialties, 'Specialty')}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {datasources ? (
            <div className={`specialty-shared-div mr-0 mr-md-3 col-md-4 ${(isSearchOrderLoading || isOrderLoading) && 'disabled'}`}>
              <div
                className={`specialty-shared mr-5 ${(isSearchOrderLoading || isOrderLoading) && 'pointer-events-none'}`}
                onClick={() => setIsDataSetDropdown(!isDataSetDropdown)}
              >
                <div className="specialty-title">
                  {newDataSourceValue === '' ? 'Data Set' : newDataSourceValue.split(',').join(', ')}
                </div>
                <div className="specialty-down-arrow">
                  <ButtonLink
                    buttonAction="Data Set dropdown clicked"
                    id={'btn-desktop-filter-dataset'}
                  >
                    <HiOutlineChevronDown />
                  </ButtonLink>
                </div>
              </div>
              {isDataSetDropdown && datasources ? (
                <div className="specialty-container" ref={dataSetRef}>
                  <div className="specialty-div">
                    <div className="specialty-body-div">
                      {getFilterList(datasources, 'DataSource')}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {tabIndex !== 0 && motivations ? (
            <div className={`specialty-shared-div mr-0 mr-md-3 col-md-4 ${(isSearchOrderLoading || isOrderLoading) && 'disabled'}`}>
              <div
                className={`specialty-shared mr-5 ${(isSearchOrderLoading || isOrderLoading) && 'pointer-events-none'}`}
                onClick={() =>
                  setIsProviderMotivationDropdown(!isProviderMotivationDropdown)
                }
              >
                <div className="specialty-title">
                  {newProviderMotivationValue === ''
                    ? 'Motivation'
                    : newProviderMotivationValue.split(',').join(', ')}
                </div>
                <div className="specialty-down-arrow">
                  <ButtonLink
                    buttonAction="Provider Motivation dropdown clicked"
                    id={'btn-desktop-filter-provider-motivation'}
                  >
                    <HiOutlineChevronDown />
                  </ButtonLink>
                </div>
              </div>
              {isProviderMotivationDropdown && motivations ? (
                <div className="specialty-container" ref={providerMotivationRef}>
                  <div className="specialty-div">
                    <div className="specialty-body-div">
                      {getFilterList(motivations, 'ProviderMotivation')}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="flex-self-center d-flex flex-justify-between mt-4 mt-md-0">
          <ButtonCustom
            cssClass="filter-btn-container d-md-none d-block"
            onClick={() => {
              setIsShowFilters(true);
              document.body.classList.add('overflow-hidden');
              document.body.classList.add('hide-chat');
            }}
            buttonAction={'Filters clicked'}
            id={'btn-mobile-filter-button'}
          >
            <div className="d-flex flex-justify-center flex-items-center">
              <div className="d-flex flex-items-center">
                <RiFilter3Fill />
              </div>
              <div className="ml-2">Filters</div>
            </div>
          </ButtonCustom>
          <div className="position-relative">
            <div className="d-flex sort-btn-container sort-btn" onClick={() => onClickOfSort()}>
              <HiOutlineSelector />
              <span>SORT</span>
            </div>
            {isDropDownFlag ? (
              <div className="drop-down">
                <ul className="dropdown-select">
                  <li
                    className={`option-list ${
                      sortBy === sortOptionEnum.OLDEST_FIRST ? 'active-sort' : ''
                    }`}
                    onClick={() => sortOrderBy(sortOptionEnum.OLDEST_FIRST)}
                    onChange={() => setFlagDropDownCard()}
                    id={sortOptionEnum.OLDEST_FIRST}
                  >
                    Oldest first
                  </li>
                  <li
                    className={`option-list ${
                      sortBy === sortOptionEnum.NEWEST_FIRST ? 'active-sort' : ''
                    }`}
                    onClick={() => sortOrderBy(sortOptionEnum.NEWEST_FIRST)}
                    onChange={() => setFlagDropDownCard()}
                    id={sortOptionEnum.NEWEST_FIRST}
                  >
                    Newest first
                  </li>
                  <li
                    className={`option-list ${
                      sortBy === sortOptionEnum.ALPHABETICAL_A_TO_Z ? 'active-sort' : ''
                    }`}
                    onClick={() => sortOrderBy(sortOptionEnum.ALPHABETICAL_A_TO_Z)}
                    onChange={() => setFlagDropDownCard()}
                    id={sortOptionEnum.ALPHABETICAL_A_TO_Z}
                  >
                    Alphabetical a-z
                  </li>
                  <li
                    className={`option-list ${
                      sortBy === sortOptionEnum.ALPHABETICAL_Z_TO_A ? 'active-sort' : ''
                    }`}
                    onClick={() => sortOrderBy(sortOptionEnum.ALPHABETICAL_Z_TO_A)}
                    onChange={() => setFlagDropDownCard()}
                    id={sortOptionEnum.ALPHABETICAL_Z_TO_A}
                  >
                    Alphabetical z-a
                  </li>
                </ul>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;
