import axios from 'axios';

export const getAllOrder = async ({ token, params }) => {
  return axios({
    method: 'post',
    url: `/prognostograms/orders/all/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getQuestionTypes = async ({ token }) => {
  return axios({
    method: 'get',
    url: '/prognostograms/orders/questions/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getMotivations = async ({ token }) => {
  return axios({
    method: 'get',
    url: '/motivations/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const orderPrognostogram = async ({ params, token, csrfToken }) => {
  return axios({
    method: 'post',
    url: '/prognostograms/orders/',
    headers: {
      "X-CSRFToken": csrfToken,
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getSearchQueryListDetails = async ({ token }) => {
  return axios({
    method: 'get',
    url: '/prognostograms/orders/search/queries/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getOrdersBySearch = async ({ token, params }) => {
  return axios({
    method: 'post',
    url: `/prognostograms/orders/search/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getOrderStatusCounts = async ({ token }) => {
  return axios({
    method: 'get',
    url: '/prognostograms/orders/statistics/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getPgPdfBytes = async ({ token, orderGuid }) => {
  return axios({
    method: 'get',
    url: `/prognostograms/orders/${orderGuid}/pdf/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const modifyPendingOrder = async ({ params, token, orderGuid }) => {
  return axios({
    method: 'put',
    url: `/prognostograms/orders/${orderGuid}/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const deleteOrder = async ({ token, orderGuid }) => {
  return axios({
    method: 'delete',
    url: `prognostograms/orders/${orderGuid}/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getSpecialtyList = async ({ token, scope, search }) => {
  let searchText = search !== undefined && search !== '' ? `&&search=${search}` : '';
  return axios({
    method: 'get',
    url: `prognostograms/orders/filters/all/?scope=${scope}${searchText}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getOrder = async ({ token, guid }) => {
  return axios({
    method: 'get',
    url: `prognostograms/orders/${guid}/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const uploadDocument = async ({ token, params }) => {
  return axios({
    method: 'post',
    url: `prognostograms/orders/documents/`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getDownloadLink = async ({ token, guid, resource }) => {
  return axios({
    method: 'post',
    url: `prognostograms/orders/${guid}/file/?file_type=${resource}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    }
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
