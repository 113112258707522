const Strings = {
  pendingDesc: 'You can still edit this request by clicking Edit Order below.',
  inProgressDesc: 'If you need to make changes to this order, ',
  completedDesc: 'Schedule a call to review your results below.',
  documentSizeError: 'Make sure your file size is less than or equal to 25MB.',
  documentExtensionError: 'Make sure your file is using any of these format (docx, PDF, CSV, xlsx, or pptx).',
  documentExtensionNote: 'Make sure to use any of these format docx, pdf, csv, xlsx, pptx.',
  phiModalText1: 'Please confirm the uploaded document does not contain identifiable medical records.',
  phiModalText2: 'Confirm the uploaded document is not an entire medical record or files with unstructured data.',
  freeSubscriptionVideoHeaderText: 'You’re on a FREE account that gives you:',
  standardSubscriptionVideoHeaderText: 'Congratulations! You\'ve subscribed to the Atropos Evidence Portal - Standard Plan.',
  standardSubscriptionVideoSubText: 'You can now request Prognostograms, and view our growing library of evidence, directly from the Portal.',
  enterpriseSubscriptionVideoHeaderText: 'Welcome to the future of evidence-based medicine!',
  enterpriseSubscriptionVideoSubText: 'For the time being, you account is on the FREE plan.',
  enterpriseSubscriptionVideoSubText1: 'If your organization has an enterprise subscription, our team will confirm and ensure your account is added to that subscription.',
  enterpriseSubscriptionVideoSubText2: 'Otherwise somebody on our team will follow-up to further discuss our offerings.',
  onboardingExistingUserText: 'Welcome back! We’re collecting a little bit more information about our users in order to build the best experience possible.',
  onboardingExistingUserTextForTOS: "Welcome back! We’ve made a few changes to our User Terms.",
  orderFirstNameError: "First Name is Required",
  orderLastNameError: "Last Name is Required",
  orderEmailError: "Email is Required",
  orderOrgnisationError: "Institution is Required",
  orderQuestionTypeError: "Question Type is Required",
  patientLastNameError : "Patient Last Name is Required",
  patientBirthDateError : "Patient Birth Date is required",
  motivationError: "Motivation is Required"
};

export { Strings };
