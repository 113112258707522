import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';

const ButtonPrimary = ({
  label,
  isDisabled,
  onClick,
  buttonAction,
  actionLabel,
  actionValue,
  id,
}) => {
  const mixpanel = useMixpanel();
  const handleOnClick = () => {
    mixpanel.track(buttonAction, {
      action_value: actionValue,
      action: actionLabel,
    });
    onClick();
  };
  return (
    <div className="input-conatainer">
      <button
        type="button"
        className={`btn-primary ${isDisabled ? 'btn-disabled' : ''}`}
        onClick={handleOnClick}
        id={id}
      >
        {label}
      </button>
    </div>
  );
};

const ButtonSecondary = ({
  label,
  cssClass,
  onClick,
  buttonAction,
  actionLabel,
  actionValue,
  id,
}) => {
  const mixpanel = useMixpanel();
  const handleOnClick = () => {
    mixpanel.track(buttonAction, {
      action_value: actionValue,
      action: actionLabel,
    });
    onClick();
  };
  return (
    <button type="button" className={cssClass} onClick={handleOnClick} id={id}>
      {label}
    </button>
  );
};

const ButtonLink = ({
  children,
  cssClass,
  route,
  buttonAction,
  actionLabel,
  actionValue,
  id,
}) => {
  const mixpanel = useMixpanel();
  let history = useHistory();
  const onClickOfButton = () => {
    mixpanel.track(buttonAction, {
      action_value: actionValue,
      action: actionLabel,
    });
    history.push(route);
  };
  return (
    <button type="button" className={cssClass} onClick={onClickOfButton} id={id}>
      {children}
    </button>
  );
};

const ButtonCustom = ({
  children,
  cssClass,
  onClick,
  disabled,
  buttonAction,
  actionLabel,
  actionValue,
  id,
}) => {
  const mixpanel = useMixpanel();
  const handleOnClick = (e) => {
    mixpanel.track(buttonAction, {
      action_value: actionValue,
      action: actionLabel,
    });
    onClick(e);
  };
  return (
    <button
      type="button"
      className={cssClass}
      onClick={handleOnClick}
      disabled={disabled}
      id={id}
    >
      {children}
    </button>
  );
};

export { ButtonPrimary, ButtonSecondary, ButtonLink, ButtonCustom };
