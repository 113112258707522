import React, { useState, useEffect } from 'react';
import { ButtonCustom } from 'components/form-input/Button';
import { CALENDY_LINK } from 'constants/Constants';
import CustomModal from './CustomModal';
import { VscChromeClose } from 'react-icons/vsc';
import AuthLoader from '../components/AuthLoader';
import { highlightText } from '../utils/highlightText';
import { useDispatch, useSelector } from 'react-redux';
import FileDownloadUtil from '../utils/FileDownloadUtil';
import resourceEnum from '../constants/enum/resourceEnum';
import { getPrognostogramPdf } from 'redux/modules/orderDetails/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { getRawToken } from '../utils/validations';
import { BsTelephone } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { BiEditAlt } from 'react-icons/bi';
import { HiOutlineEye } from 'react-icons/hi';
import { MdOutlineCancel } from 'react-icons/md';
import { TbFileDownload } from 'react-icons/tb';

const PicotOptions = ({
  orderGuid,
  population,
  intervention,
  control,
  outcome,
  timeframe,
  onModify,
  openPDF,
  questionStatus,
  onEdit,
  onCancelOrder,
  tabIndex,
  textToHighlight,
  conclusion,
  summary,
}) => {
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  const [showModal, setShowModal] = useState(false);
  const [isOpenPdf, setSetOpenPdf] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const {
    resource,
    isFetchingDownloadLinkStarted,
    getFileDownloadError,
    pdfFile,
    isPdfLoading,
  } = useSelector(({ orderDetails }) => orderDetails);
  const { getDownloadUrl } = FileDownloadUtil();

  const highlightPicotOptions = (context, picotOptions) => {
    if (textToHighlight && textToHighlight.hasOwnProperty(picotOptions)) {
      for (var key in textToHighlight) {
        if (key === picotOptions) {
          var highlightObj = textToHighlight[key];
          if (highlightObj.text) {
            return highlightText(context, highlightObj.text);
          } else {
            return context;
          }
        }
      }
    } else {
      return context;
    }
  };

  const OnClickOfClose = () => {
    setShowModal(false);
  };
  const onClickOfCancelOrder = () => {
    setShowModal(true);
  };

  const onDownloadArtifactsButton = async () => {
    getDownloadUrl(orderGuid, resourceEnum.ARTIFACTS);
  };

  const getPdfData = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      getPrognostogramPdf({
        token: getRawToken(token),
        orderGuid: orderGuid,
      }),
    );
  };

  return (
    <div className="my-3">
      <CustomModal
        isOpen={showModal}
        handleClose={OnClickOfClose}
        cssClass="white-overlay"
        modalname="Order cancel modal"
        overlayCssClass={'cancel-modal-overlay'}
      >
        <div className="email-modal p-5 delete-modal text-center">
          <ButtonCustom
            cssClass="close-btn"
            onClick={() => OnClickOfClose()}
            buttonAction={'Close cancel modal button clicked'}
            id={'btn-cancel-modal-close-button'}
          >
            <VscChromeClose />
          </ButtonCustom>
          <div className="profile-sub-header mt-4 mb-2">Are you sure?</div>
          <div className="cancel-sub-header mt-4">
            This Prognostogram will be cancelled.
          </div>
          <div className="d-flex flex-justify-between">
            <ButtonCustom
              cssClass="plain-btn mt-5"
              onClick={onCancelOrder}
              buttonAction={'Cancel order button clicked'}
              actionLabel={'Order is canceled'}
              id={'btn-cancel-order-button'}
            >
              Cancel order
            </ButtonCustom>
            <ButtonCustom
              cssClass="btn-primary mt-5 px-4"
              onClick={() => OnClickOfClose()}
              buttonAction={'Order keep it button clicked'}
              actionLabel={'Order does not cancel'}
              id={'btn-keep-it-button'}
            >
              Keep it
            </ButtonCustom>
          </div>
        </div>
      </CustomModal>
      {summary && (
        <div className="picot-desc mb-4">
          <div className="picot-lable mb-2 picot-label-color">Summary</div>
          <div className="picot-content">{highlightPicotOptions(summary, 'summary')}</div>
        </div>
      )}
      {conclusion && (
        <div className="picot-desc mb-4">
          <div className="picot-lable mb-2 picot-label-color">Conclusions</div>
          <div className="picot-content">
            {highlightPicotOptions(conclusion, 'conclusion')}
          </div>
        </div>
      )}
      {population && (
        <div className="picot-desc mb-4">
          <div className="picot-lable mb-2">Population</div>
          <div className="picot-content">
            {highlightPicotOptions(population, 'population')}
          </div>
        </div>
      )}
      {intervention && (
        <div className="picot-desc mb-4">
          <div className="picot-lable mb-2">Intervention</div>
          <div className="picot-content">
            {highlightPicotOptions(intervention, 'intervention')}
          </div>
        </div>
      )}
      {control && (
        <div className="picot-desc mb-4">
          <div className="picot-lable mb-2">Control</div>
          <div className="picot-content">{highlightPicotOptions(control, 'control')}</div>
        </div>
      )}
      {outcome && (
        <div className="picot-desc mb-4">
          <div className="picot-lable mb-2">Outcome</div>
          <div className="picot-content">{highlightPicotOptions(outcome, 'outcome')}</div>
        </div>
      )}
      {timeframe && (
        <div className="picot-desc mb-4">
          <div className="picot-lable mb-2">Timeframe</div>
          <div className="picot-content">
            {highlightPicotOptions(timeframe, 'timeframe')}
          </div>
        </div>
      )}
      <div className="btn-container">
        <div className="d-md-flex flex-md-justify-center">
          {tabIndex !== 2 ? (
            <a
              href={CALENDY_LINK}
              target="_blank"
              className="blue-btn my-2 my-md-4 d-flex flex-justify-center flex-items-center"
              rel="noreferrer"
              id={'btn-schedule-call-button'}
            >
              <div className="icon-button d-flex flex-justify-center ">
                <BsTelephone />
                <span>Schedule a call</span>
              </div>
            </a>
          ) : (
            ''
          )}
          <ButtonCustom
            cssClass="blue-btn my-2 my-md-4"
            onClick={onModify}
            buttonAction={'Modify and reorder button clicked'}
            id={'bt-modify-and-reorder-bbutton'}
          >
            <div className="icon-button d-flex flex-justify-center ">
              <FaRegEdit />
              <span>Modify and reorder</span>
            </div>
          </ButtonCustom>
          {questionStatus === 'Pending' ? (
            <ButtonCustom
              cssClass="blue-btn my-2 my-md-4"
              onClick={onEdit}
              buttonAction={'Edit order button clicked'}
              id={'btn-edit-order-button'}
            >
              <div className="icon-button d-flex flex-justify-center ">
                <BiEditAlt />
                <span>Edit order</span>
              </div>
            </ButtonCustom>
          ) : (
            <div className="position-relative loader-btn">
              <ButtonCustom
                cssClass={`blue-btn my-2 my-md-4 ${
                  questionStatus !== 'Completed' ? 'btn-disabled' : ''
                }`}
                disabled={questionStatus !== 'Completed' ? true : false}
                onClick={getPdfData}
                buttonAction={'View prognostogram button clicked'}
                id={'btn-view-prognostogram-button'}
              >
                <div className="icon-button d-flex flex-justify-center ">
                  <HiOutlineEye />
                  <span>View Prognostogram</span>
                </div>
              </ButtonCustom>
              {showLoader === true || (isPdfLoading && questionStatus === 'Completed') ? (
                <div className="loader-btn-view">
                  <AuthLoader fullScreen={false} />
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {questionStatus === 'Completed' ? (
            <div className="position-relative loader-btn">
              <ButtonCustom
                cssClass="blue-btn my-2 my-md-4"
                onClick={onDownloadArtifactsButton}
                buttonAction={'Download artifacts button clicked'}
                id={'btn-edit-order-button'}
              >
                <div className="icon-button d-flex flex-justify-center ">
                  <TbFileDownload />
                  <span>Download Artifacts</span>
                </div>
              </ButtonCustom>
              {isFetchingDownloadLinkStarted ? (
                <div className="loader-btn-view">
                  <AuthLoader fullScreen={false} />
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {questionStatus === 'Pending' ? (
            <ButtonCustom
              cssClass="blue-btn my-2 my-md-4"
              onClick={onClickOfCancelOrder}
              buttonAction={'Order cancel button clicked'}
              id={'btn-cancel-orders-button'}
            >
              <div className="icon-button d-flex flex-justify-center ">
                <MdOutlineCancel />
                <span>Cancel order</span>
              </div>
            </ButtonCustom>
          ) : (
            ''
          )}
        </div>
        {getFileDownloadError && resource === resourceEnum.ARTIFACTS ? (
          <div className="mb-3 color-error error-text text-center">
            {getFileDownloadError}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default PicotOptions;
