import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({
  value,
  isError,
  placeholder,
  onChange,
  onClick,
  onKeyUp,
  onFocus,
  onBlur,
  isFloatingLabel,
  errorText,
  disabled,
  label,
  onKeyPress,
  cssClass,
  id,
}) => {
  const handleChange = (e) => {
    !!onChange && onChange(e);
  };

  const handleClick = (e) => {
    !!onClick && onClick(e);
  };

  const handleKeyUp = (e) => {
    !!onKeyUp && onKeyUp(e);
  };

  const handleFocus = (e) => {
    !!onFocus && onFocus(e);
  };

  const handleBlur = (e) => {
    !!onBlur && onBlur(e);
  };

  const handleKeyPress = (e) => {
    !!onKeyPress && onKeyPress(e);
  };

  return (
    <div className="input-conatainer">
      {label && !isFloatingLabel ? <div className="input-label">{label}</div> : ''}
      <input
        type="text"
        className={`inputText${isError ? ' border-error' : ''} ${cssClass}`}
        value={value}
        placeholder={!isFloatingLabel && !label ? placeholder : ''}
        onChange={(e) => handleChange(e)}
        onClick={(e) => handleClick(e)}
        onKeyUp={(e) => handleKeyUp(e)}
        onFocus={(e) => handleFocus(e)}
        onBlur={(e) => handleBlur(e)}
        onKeyPress={(e) => handleKeyPress(e)}
        required
        disabled={disabled}
        id={id}
        autoComplete="off"
      />
      {isFloatingLabel ? <span className="floating-label">{placeholder}</span> : ''}
      {isError ? <div className="color-error error-text">{errorText}</div> : ''}
    </div>
  );
};

TextInput.propTypes = {
  value: PropTypes.string,
  errorText: PropTypes.string,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

TextInput.defaultProps = {
  value: '',
  isError: false,
  placeholder: '',
  errorText: '',
  disabled: false,
};

export default TextInput;
