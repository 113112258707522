import React from 'react';
import Logo from 'assets/images/atropos_logo.png';
import { SelectMenu, Button } from '@primer/components';
import { useHistory } from 'react-router-dom';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import { converBase64toLink } from '../utils/converBase64toLink';
import { BsFillPersonFill } from 'react-icons/bs';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonCustom } from './form-input/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import LogOutUtil from '../utils/LogOutUtil';

const profileImage = (name, profilePicture) => {
  if (profilePicture) {
    let profil_pic = converBase64toLink(profilePicture);
    if (profil_pic) {
      return <img src={profil_pic} alt={name} />;
    } else {
      return (
        <div className="d-flex flex-justify-center flex-items-center nav-img">
          {name.trim() !== '' ? (
            <span>{name.charAt(0).toUpperCase()} </span>
          ) : (
            <span>
              <BsFillPersonFill />
            </span>
          )}
        </div>
      );
    }
  } else {
    return (
      <div className="d-flex flex-justify-center flex-items-center nav-img">
        {name.trim() !== '' ? (
          <span>{name.charAt(0).toUpperCase()} </span>
        ) : (
          <span>
            <BsFillPersonFill />
          </span>
        )}
      </div>
    );
  }
};

const TopNav = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { logOutUser } = LogOutUtil();
  const { orderScopeData } = useSelector(({ orderDetails }) => orderDetails);
  const { userData } = useSelector(({ userProfile }) => userProfile);
  const { first_name = '', last_name = '', profile_photo = '' } = userData || {};
  const onClickOfButton = () => {
    mixpanel.track('Profile button clicked');
    history.push(urlConstantsEnum.PROFILE);
  };

  const logOutCallbackFuc = () => {
    logOutUser();
  };

  const OnClickOfLogin = () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}`,
      appState: { returnTo: window.location.pathname },
    });
  };

  const navigateToDashboard = () => {
    history.push(
      orderScopeData?.scopeTabIndex === 1
        ? urlConstantsEnum.LIBRARY_DASHBOARD
        : urlConstantsEnum.DASHBOARD,
    );
  };

  return (
    <div className="topnav d-flex flex-justify-between">
      <div className="logoContainer d-flex flex-content-center">
        <ButtonCustom
          cssClass="portal-logo"
          onClick={() => navigateToDashboard()}
          id={'btn-logo-link'}
        >
          <img src={Logo} alt="Atropos logo" />
        </ButtonCustom>
      </div>
      <div className="d-flex">
        {isAuthenticated ? (
          <SelectMenu>
            <Button as="summary" id={'btn-profile-button'}>
              <div className="avatar h6 lh-condensed-ultra d-flex">
                {profileImage(first_name + ' ' + last_name, profile_photo)}
              </div>
            </Button>
            <SelectMenu.Modal>
              <SelectMenu.List>
                <SelectMenu.Item onClick={onClickOfButton} id={'btn-profile-page-btn'}>
                  Profile
                </SelectMenu.Item>
                <SelectMenu.Item
                  onClick={() => logOutCallbackFuc()}
                  id={'btn-log-out-button'}
                >
                  Log Out
                </SelectMenu.Item>
              </SelectMenu.List>
            </SelectMenu.Modal>
          </SelectMenu>
        ) : (
          <ButtonCustom
            onClick={() => OnClickOfLogin()}
            cssClass="gradient-btn signin-btn"
            buttonAction="Sign in button clicked"
            id={'btn-sign-in-button'}
          >
            Sign In
          </ButtonCustom>
        )}
      </div>
    </div>
  );
};
export default TopNav;
