import React, { useState, useEffect, useRef } from 'react';
import TextInput from 'components/form-input/TextInput';
import { ButtonPrimary } from 'components/form-input/Button';
import orderFormErrorStr from 'constants/errorStrings';
import OrderPrognostogramHeader from 'components/OrderPrognostogramHeader';
import { validateAsText, validateEmail } from 'utils/validations';
import { useHistory } from 'react-router-dom';
import { setOrderFormData, setSearchQueryText } from 'redux/modules/orderDetails/actions';
import { useDispatch, useSelector } from 'react-redux';
import orderHeadereEnum from '../constants/enum/orderHeadereEnum';
import AuthLoader from 'components/AuthLoader';
import urlConstantsEnum from '../constants/enum/urlConstantsEnum';
import { useAuth0 } from '@auth0/auth0-react';
import Select from 'react-select';
import { getSubscriptionDataset } from 'redux/modules/userSubscription/actions';
import {
  getPhysicianInstituteList,
  requestGetUserProfile,
  createNewUser,
  clearProfileError,
} from 'redux/modules/userProfile/actions';
import { useMixpanel } from 'react-mixpanel-browser';
import { getRawToken } from '../utils/validations';
import Cookies from 'js-cookie';

const RequestPrognostogram = () => {
  let history = useHistory();
  const mixpanel = useMixpanel();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  const { orderFormData, orderScopeData } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const [url, setUrl] = useState('');
  const {
    isLoading,
    userData,
    instituteData = [],
    isNewUserCreateStarted,
    createNewUserError,
  } = useSelector(({ userProfile }) => userProfile);

  const {
    first_name = '',
    email = '',
    emails = [],
    last_name = '',
    institute = {},
  } = userData || {};
  let initialState = {
    firstName: {
      value: orderFormData?.firstName ? orderFormData.firstName : first_name,
      isValid: true,
      errorText: orderFormErrorStr.firstName,
    },
    lastName: {
      value: orderFormData?.lastName ? orderFormData.lastName : last_name,
      isValid: true,
      errorText: orderFormErrorStr.lastName,
    },
    otherOrganization: {
      value: orderFormData?.otherOrganization
        ? orderFormData.otherOrganization
        : !institute?.is_global ? institute?.name : '',
      isValid: true,
      errorText: 'Please select a valid Organization',
    },
    emailIds: {
      value: [],
    },
    newEmail: {
      value: [
        {
          label: orderFormData?.email ? orderFormData.email : email,
          value: orderFormData?.email ? orderFormData.email : email,
        },
      ],
      isValid: true,
      errorText: orderFormErrorStr.email,
    },
    physicianInstitution: {
      value: orderFormData?.physicianInstitution
        ? orderFormData.physicianInstitution
        : institute?.name,
      isValid: true,
      errorText: orderFormErrorStr.physicianInstitution,
    },
    email: {
      value: orderFormData?.email ? orderFormData.email : email,
      isValid: true,
      errorText: orderFormErrorStr.email,
    },
  };

  const [organizationSelectedOption, setOrganizationSelectedOption] = useState(null);
  const [formState, setFormState] = useState(initialState);
  const [newInstituteList, setNewInstituteList] = useState([]);

  //generic handles
  const handleFocus = (name) => {
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], isValid: true },
    }));
  };

  const handleChange = (value, name) => {
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], value: value },
    }));
  };

  const handleFirstNameBlur = () => {
    setFormState((formState) => ({
      ...formState,
      firstName: {
        ...formState.firstName,
        value: formState.firstName.value.trim(),
        isValid: validateAsText(formState.firstName.value),
      },
    }));
  };

  const handleLastNameBlur = () => {
    setFormState((formState) => ({
      ...formState,
      lastName: {
        ...formState.lastName,
        value: formState.lastName.value.trim(),
        isValid: validateAsText(formState.lastName.value),
      },
    }));
  };

  const handleOtherOrganizationBlur = () => {
    setFormState((formState) => ({
      ...formState,
      otherOrganization: {
        ...formState.otherOrganization,
        value: formState.otherOrganization.value !== undefined ? formState.otherOrganization.value.trim() : '',
        isValid: validateAsText(formState.otherOrganization.value),
      },
    }));
  };

  const onClickOfInstituteName = (institute, guid) => {
    setFormState((formState) => ({
      ...formState,
      physicianInstitution: {
        ...formState.physicianInstitution,
        isValid: true,
        value: institute,
      },
    }));
    setInstituteGuid(guid);
  };

  const isFormValid = () => {
    return (
      validateAsText(formState.firstName.value) &&
      validateAsText(formState.lastName.value) &&
      validateEmailId()
    );
  };

  const validateEmailId = () => {
    return formState.newEmail.value[0].value
      ? validateEmail(formState.newEmail.value[0].value)
      : validateEmail(formState.email.value);
  };
  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      submitProfileInfo();
    }
  };

  useEffect(() => {
    if (orderFormData?.previous_order_guid && orderFormData?.isEditOrder) {
      setUrl('/edit/' + orderFormData?.previous_order_guid);
    } else if (orderFormData?.previous_order_guid && !orderFormData?.isEditOrder) {
      setUrl('/reorder/' + orderFormData?.previous_order_guid);
    } else {
      setUrl('');
    }
  }, []);

  useEffect(() => {
    const getProfileData = async () => {
      const token = await getIdTokenClaims();
      if (token) {
        dispatch(
          requestGetUserProfile({
            token: getRawToken(token),
          }),
        );
      }
    };
    getProfileData();
  }, []);

  const submitProfileInfo = () => {
    if (isFormValid() && checkIfOrgaisationIsValid()) {
      if (isAuthenticated) {
        redirectToNextScreen();
      } else {
        createNewUserProfile();
      }
    }
  };

  const createNewUserProfile = async () => {
    dispatch(
      createNewUser({
        params: {
          first_name: formState.firstName.value,
          last_name: formState.lastName.value,
          email: formState.email.value,

          institute_name: organizationSelectedOption
            ? organizationSelectedOption.label === 'Other'
              ? formState.otherOrganization.value
              : organizationSelectedOption.label
            : '',
          institute_guid: organizationSelectedOption
            ? organizationSelectedOption.label === 'Other'
              ? ''
              : organizationSelectedOption.value
            : '',
        },
        csrfToken: Cookies.get('csrftoken'),
        successCallback: redirectToNextScreen,
      }),
    );
  };

  const redirectToNextScreen = () => {
    let orderProfileFormData = {
      question_type: orderFormData?.question_type,
      firstName: formState.firstName.value,
      lastName: formState.lastName.value,
      email: formState?.newEmail?.value[0]?.value
        ? formState.newEmail.value[0].value
        : formState.email.value
        ? formState.email.value
        : '',
      questionType: orderFormData?.questionType,
      clinicalQuestion: orderFormData?.clinicalQuestion,
      previous_order_guid: orderFormData?.previous_order_guid,
      questionTitle: orderFormData?.questionTitle,
      population: orderFormData?.population,
      intervention: orderFormData?.intervention,
      control: orderFormData?.control,
      outcome: orderFormData?.outcome,
      timeframe: orderFormData?.timeframe,
      picotSwitch: orderFormData?.picotSwitch,
      orderStatus: orderFormData?.orderStatus,
      isEditOrder: orderFormData?.isEditOrder,
      institute_name: organizationSelectedOption
        ? organizationSelectedOption.label === 'Other'
          ? formState.otherOrganization.value
          : organizationSelectedOption.label
        : '',
      institute_guid: organizationSelectedOption
        ? organizationSelectedOption.label === 'Other'
          ? 'other'
          : organizationSelectedOption.value
        : '',
      data_source: orderFormData?.data_source,
      sharing_type: orderFormData?.sharing_type,
      otherOrganization: formState.otherOrganization.value,
      physicianInstitution: organizationSelectedOption.label,
      urgencyType: orderFormData?.urgencyType,
      phiDisclaimer: orderFormData?.phiDisclaimer,
      documentFileName: orderFormData?.documentFileName,
    };
    dispatch(setOrderFormData(orderProfileFormData));
    history.push(urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_TWO + url);
  };

  const onClickOfBackBtn = () => {
    history.push(
      orderScopeData?.scopeTabIndex === 1
        ? urlConstantsEnum.LIBRARY_DASHBOARD
        : urlConstantsEnum.DASHBOARD,
    );
  };

  useEffect(() => {
    if (emails) {
      var newEmailArray = [];
      emails.forEach((emailObj) => {
        newEmailArray.push(emailObj.email);
      });
      setFormState((formState) => ({
        ...formState,
        emailIds: { value: newEmailArray },
      }));
    }
  }, [userData]);

  const handleEmailChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      newEmail: { ...formState.newEmail, value: [value] },
    }));
    mixpanel.track('Email changed event', {
      selected_email: value,
    });
  };

  const handleOnEmailBlur = () => {
    setFormState((formState) => ({
      ...formState,
      email: {
        ...formState.email,
        isValid: validateEmail(formState.email.value),
      },
    }));
  };

  const handleOrganizationChange = (organization) => {
    if (organization) {
      if (organization.label !== 'Other' && formState.otherOrganization.value !== '') {
        setFormState((formState) => ({
          ...formState,
          otherOrganization: { ...formState.otherOrganization, value: '' },
        }));
      }
    }
    mixpanel.track('Organization changed event', {
      selected_organization: organization,
    });
    setOrganizationSelectedOption(organization);
  };

  useEffect(() => {
    const getInstituteList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getPhysicianInstituteList({
          params: {
            institute_name: '',
          },
          token: token ? token.__raw : '',
        }),
      );
    };
    const getDatasetList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getSubscriptionDataset({
          token: token ? token.__raw : '',
        }),
      );
    };
    getDatasetList();
    getInstituteList();
  }, [dispatch]);

  useEffect(() => {
    dispatch(setSearchQueryText({}));
    dispatch(clearProfileError());
  }, []);

  useEffect(() => {
    if (instituteData?.length > 0) {
      setInstitutelistOnLoad();
    }
  }, [instituteData]);

  const setInstitutelistOnLoad = () => {
    var newInstituteData = instituteData;
    var otherIndex = newInstituteData.find((o) => o.name === 'Other');
    if (otherIndex === undefined) {
      newInstituteData.unshift({
        guid: 'other',
        name: 'Other',
      });
    }
    const newInstitute = newInstituteData?.map(
      ({ name: label, guid: value, ...rest }) => ({
        label,
        value,
        ...rest,
      }),
    );
    setNewInstituteList(newInstitute);
  };

  const setOrganization = (value) => {
    newInstituteList.forEach((institute) => {
      if (institute.label === value) {
        setOrganizationSelectedOption(institute);
      }
    });
  };

  useEffect(() => {
    if (orderFormData?.physicianInstitution) {
      setOrganization(orderFormData?.physicianInstitution);
    } else if (institute) {
      setOrganization(institute?.name);
    }
  }, [
    orderFormData?.physicianInstitution,
    institute,
    instituteData,
    newInstituteList,
  ]);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: '8',
    }),
    control: (provided, state) => ({
      ...provided,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: '0',
      boxShadow: 'none',
      borderBottom: '1px solid #ccc !important',
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#000',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#000',
      backgroundColor: state.isSelected
        ? '#0D3F3C'
        : state.isFocused
        ? '#d8eeee'
        : '#fff',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    },
  };

  const checkIfOrgaisationIsValid = () => {
    if (organizationSelectedOption === null) {
      return false;
    } else if (organizationSelectedOption.label === 'Other') {
      if (
        formState.otherOrganization.value !== '' &&
        formState.otherOrganization.isValid
      ) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const isSubmitDisabled = isFormValid() && checkIfOrgaisationIsValid();

  return isLoading || isNewUserCreateStarted ? (
    <AuthLoader fullScreen={true} />
  ) : (
    <div className="order-container">
      <OrderPrognostogramHeader
        isShowBackArrow={isAuthenticated}
        headerArray={[
          { name: orderHeadereEnum.PROFILE_INFO, isActive: true, isCompleted: false },
          { name: orderHeadereEnum.QUESTION_TYPE, isActive: false, isCompleted: false },
          {
            name: orderHeadereEnum.CLINICAL_QUESTION,
            isActive: false,
            isCompleted: false,
          },
        ]}
        onClickOfBackBtn={onClickOfBackBtn}
        headerText={
          orderFormData?.previous_order_guid && orderFormData?.isEditOrder
            ? 'Edit Order'
            : orderFormData?.previous_order_guid && !orderFormData?.isEditOrder
            ? 'Modify and Reorder'
            : 'Request a Prognostogram'
        }
      />
      <div className="order-section-container">
        <TextInput
          placeholder="First Name"
          isFloatingLabel={true}
          value={formState.firstName.value}
          isError={!formState.firstName.isValid}
          errorText={formState.firstName.errorText}
          onChange={(e) => handleChange(e.target.value, 'firstName')}
          onFocus={() => handleFocus('firstName')}
          onBlur={handleFirstNameBlur}
          id={'textInput-first-name'}
        />
        <TextInput
          placeholder="Last Name"
          isFloatingLabel={true}
          value={formState.lastName.value}
          isError={!formState.lastName.isValid}
          errorText={formState.lastName.errorText}
          onChange={(e) => handleChange(e.target.value, 'lastName')}
          onFocus={() => handleFocus('lastName')}
          onBlur={handleLastNameBlur}
          id={'textInput-last-name'}
        />
        {formState.newEmail.value[0].value && isAuthenticated ? (
          <>
            <div className="organization-label">Email</div>
            <Select
              options={formState.emailIds.value.map((email) => ({
                label: email,
                value: email,
              }))}
              value={formState.newEmail.value}
              onChange={handleEmailChange}
              styles={customStyles}
              isClearable={false}
              isSearchable={false}
              id={'select-email'}
            />
          </>
        ) : (
          <TextInput
            placeholder="Email"
            isFloatingLabel={true}
            value={formState.email.value}
            isError={!formState.email.isValid}
            errorText={formState.email.errorText}
            onChange={(e) => handleChange(e.target.value, 'email')}
            onFocus={() => handleFocus('email')}
            onBlur={handleOnEmailBlur}
            id={'textInput-email'}
          />
        )}
        <div className="organization-label mb-1">Organization</div>
        <Select
          options={newInstituteList}
          value={organizationSelectedOption}
          onChange={handleOrganizationChange}
          styles={customStyles}
          isDisabled={
            isAuthenticated && organizationSelectedOption !== null ? true : false
          }
          placeholder={''}
          isClearable={true}
          onKeyPress={handleKeypress}
          id={'select-organization'}
          noOptionsMessage={({ inputValue: string }) =>
            'Please select a valid organization or select Other'
          }
        />
        {organizationSelectedOption !== null ? (
          <>
            {organizationSelectedOption?.label === 'Other' ? (
              <TextInput
                placeholder="Other organization"
                isFloatingLabel={true}
                value={formState.otherOrganization.value}
                isError={!formState.otherOrganization.isValid}
                errorText={formState.otherOrganization.errorText}
                onChange={(e) => handleChange(e.target.value, 'otherOrganization')}
                onFocus={() => handleFocus('otherOrganization')}
                onBlur={handleOtherOrganizationBlur}
                onKeyPress={handleKeypress}
                id={'textInput-other-organization'}
              />
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        <ButtonPrimary
          label="Confirm and select question type"
          isDisabled={!isSubmitDisabled}
          onClick={submitProfileInfo}
          buttonAction={'Confirm profile info button clicked'}
          actionLabel={'Order profile info'}
          id={'btn-connfirm-profile-info'}
        />
        {!!createNewUserError ? (
          <div className="color-error error-text">{createNewUserError}</div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default RequestPrognostogram;
