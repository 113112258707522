import React, { useState, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import Switch from 'react-switch';
import { ButtonCustom } from 'components/form-input/Button';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  setOnboardingData,
  updateProfileInfo,
  requestGetUserProfile,
  completeProfile,
  setVideoModalDisplay,
} from 'redux/modules/userProfile/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getRawToken } from '../utils/validations';
import { useHistory } from 'react-router-dom';
import urlConstantsEnum from '../constants/enum/urlConstantsEnum';
import AuthLoader from 'components/AuthLoader';
import {
  subscriptionObjEnum,
  subscriptionEnum,
  subscriptionFrequencyEnum,
} from '../constants/enum/subscriptionEnum';

const TermsOfServices = ({ onClickOfTosBackBtn, onClickOfTosBtn }) => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  let history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const {
    onboardingData,
    userData,
    isLoading,
    updateProfileError,
    instituteData,
    isProfileUpdateStarted,
    isUpdatedProfileCompleted,
  } = useSelector(({ userProfile }) => userProfile);

  const { institute = {} } = userData || {};

  const { orderScopeData } = useSelector(({ orderDetails }) => orderDetails);
  const [tosSwitch, setTosSwitch] = useState(
    onboardingData?.agreed_terms_of_service
      ? onboardingData?.agreed_terms_of_service
      : userData?.agreed_terms_of_service
      ? userData?.agreed_terms_of_service
      : false,
  );
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCallProfileCompleted, setCallProfileCompleted] = useState(false);
  const [isHealthSystemOrgnisation, setHealthSystemOrgnisation] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);

  useEffect(() => {
    if (institute) {
      setHealthSystemOrgnisation(institute?.is_health_system_or_provider);
    }
  }, [institute]);

  const handleSwitchChange = (checked) => {
    checked ? setShowErrorMessage(false) : setShowErrorMessage(true);
    mixpanel.track(`User ${checked ? 'agree' : 'disagree'} terms of services`);
    setTosSwitch(checked);
  };
  const onClickOfTosConfirmBtn = () => {
    if (tosSwitch) {
      setShowErrorMessage(false);
      if (userData?.subscription_information === null) {
        if (isHealthSystemOrgnisation) {
          saveUsersSettings();
        } else {
          saveInformation();
          !!onClickOfTosBtn && onClickOfTosBtn();
        }
      } else {
        //call save information API
        saveUsersSettings();
      }
    } else {
      setShowErrorMessage(true);
    }
  };

  const saveUsersSettings = async () => {
    let freeSubscription = {};
    if (
      userData?.is_existing_user &&
      !userData?.agreed_terms_of_service &&
      userData?.is_profile_completed
    ) {
      setIsExistingUser(true);
    } else {
      setIsExistingUser(false);
    }

    subscriptionObjEnum.forEach((subscription) => {
      if (
        subscription.name === subscriptionEnum.FREE &&
        subscription.frequency === subscriptionFrequencyEnum.YEARLY
      ) {
        freeSubscription = subscription;
      }
    });
    const token = await getIdTokenClaims();
    var params = {
      agreed_terms_of_service: tosSwitch,
    };
    var subscription = '';
    if (isHealthSystemOrgnisation && userData?.subscription_information === null) {
      params.subscription_guid = freeSubscription.guid;
      subscription = freeSubscription.name;
    }
    dispatch(
      updateProfileInfo({
        params: params,
        userGuid: userData.guid,
        token: getRawToken(token),
      }),
    );
    setCallProfileCompleted(true);
    document.body.classList.remove('hide-chat');
    dispatch(setOnboardingData({}));
  };

  const getProfileData = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      requestGetUserProfile({
        token: getRawToken(token),
      }),
    );
  };

  useEffect(() => {
    history.push(urlConstantsEnum.ONBOARDING_STEP_2);
  }, []);

  useEffect(() => {
    if (isUpdatedProfileCompleted && isCallProfileCompleted) {
      redirectToHomeScreen();
    }
  }, [isUpdatedProfileCompleted]);

  const redirectToHomeScreen = async (subscription) => {
    const token = await getIdTokenClaims();
    dispatch(completeProfile({ userGuid: userData.guid, token: getRawToken(token) }));
    var subscriptionType = '';
    if (subscription) {
      subscriptionType = subscription;
    } else {
      subscriptionType = userData?.subscription_information.name;
    }
    if (!isExistingUser) {
      dispatch(
        setVideoModalDisplay({
          isDisplayModal: true,
          subscriptionType: subscriptionType,
        }),
      );
    }
    
    getProfileData();
    history.push(
      orderScopeData?.scopeTabIndex === 1
        ? urlConstantsEnum.LIBRARY_DASHBOARD
        : urlConstantsEnum.DASHBOARD,
    );
  };

  const onClickOfTosBackButton = () => {
    saveInformation();
    !!onClickOfTosBackBtn && onClickOfTosBackBtn();
  };

  const saveInformation = () => {
    let onboardingDataObj = {
      agreed_terms_of_service: tosSwitch,
      subscription_guid: onboardingData?.subscription_guid,
      subscription_frequency: onboardingData?.subscription_frequency,
    };
    dispatch(setOnboardingData(onboardingDataObj));
  };

  return (
    <>
      {isLoading || isProfileUpdateStarted ? (
        <div className="position-relative">
          <AuthLoader fullScreen={false} />
        </div>
      ) : (
        <>
          {showErrorMessage && (
            <div className="d-flex onboarding-error-container mt-4">
              <div className="mr-3 flex-self-center">
                <RiErrorWarningFill className="d-block" />
              </div>
              <div className="flex-self-center">
                Please accept our Terms of Service to continue
              </div>
            </div>
          )}
          <div className="onboarding-section-header my-4">Terms of Service</div>
          <div className="tos-bg p-3">
            <div className={`tos-container p-3 ${showErrorMessage ? 'tos-error' : ''}`}>
              <div className="mb-3">
                These Atropos User Terms (“<strong>Terms</strong>”) set out terms and
                conditions for you to access and use Geneva OS (“
                <strong>Geneva OS</strong>” or “<strong>Platform</strong>”), and to
                generate or receive related reports and other output from the Platform (“
                <strong>Evidence-Based Deliverables</strong>”), as applicable. The
                Platform and the Evidence-Based Deliverables, and any other Atropos
                Health, Inc. (“<strong>Atropos</strong>”, “<strong>we</strong>”, “
                <strong>us</strong>”, or “<strong>our</strong>”) products or services that
                link to these Terms, are collectively referred to herein as the “
                <strong>Services</strong>.” You and Atropos may collectively be referred
                to herein as the “parties.”
              </div>

              <div className="mb-3">
                Your use of the Services, or any part thereof, is subject to (1) the
                commercial agreement and any applicable order form(s) in place between you
                or the organization that you are affiliated with, and Atropos and (2) any
                agreement in place between you and the organization that you are
                affiliated with (1 and 2 are collectively the “<strong>Agreements</strong>
                ”).
              </div>
              <div className="mb-3">
                These Terms are incorporated into and made a part of the Agreements and
                are not meant to restrict or limit any other obligations that you or your
                organization may have under the Agreements. All references in these Terms
                to “you” or “you” shall also refer to and include the organization with
                which you are affiliated.
              </div>
              <div className="mb-3">
                By using the Services (or receiving an Evidence-Based Deliverable), you
                are agreeing to these Terms. If you do not agree to these Terms, you may
                not use the Services or request an Evidence-Based Deliverable.
              </div>
              <div className="mb-3">
                Any capitalized terms not defined in these Terms shall have the meaning
                stated in the Agreements.
              </div>
              <ol>
                <li>
                  <strong>Permitted Use and Access. </strong>Your use of the Services is
                  subject to your compliance with these Terms and the Agreements, and all
                  applicable law. Your use may also be subject to additional terms and
                  conditions specified by the organization that you are affiliated with.
                  In order to use the Services, you or the organization that you are
                  affiliated with must have a valid agreement in place with Atropos that
                  permits your use. You must be at least eighteen (18) years old to use
                  the Services. Access to the Services is limited to your organization’s
                  employees, patients, and contractors, who need to access the Services
                  for purposes of their work for your organization and are bound by
                  confidentiality obligations no less restrictive than those contained in
                  these Terms and the Agreements.
                </li>
                <li>
                  <strong>Role of Atropos and Disclaimer. </strong>You acknowledge that
                  Atropos is not engaged in rendering medical, pharmacologic or health
                  care advice or services and the professional duty to the patient in
                  providing healthcare services lies solely with the applicable healthcare
                  provider and/or medical facility (“<strong>Medical Facility</strong>”).
                  Evidence-Based Deliverables are intended to be a supplement to, and not
                  a substitute for the knowledge, expertise, skill, and judgment of the
                  Medical Facility in patient care. They are in no way intended to serve
                  as a diagnostic service or platform, to provide certainty with respect
                  to a diagnosis, to recommend a particular product or therapy or to
                  otherwise substitute for the clinical judgment of a qualified healthcare
                  professional. Atropos does not assume any responsibility for the actions
                  of any Medical Facility which may result in liability or damages due to
                  malpractice, failure to warn, negligence, or any other basis.
                </li>
                <li>
                  <strong>Use of Evidence-Based Deliverables. </strong>You may only use
                  Evidence-Based Deliverables as permitted in and in accordance with the
                  Agreements, and all applicable law. You agree to carefully review and
                  analyze all Evidence-Based Deliverables. You are responsible for the
                  adoption and implementation of any and all Evidence-Based Deliverables
                  and for all results and consequences of decisions made by or on your
                  behalf. Atropos is not responsible for your use of or reliance on any
                  Evidence-Based Deliverables. You may not share, distribute, or disclose
                  any Evidence-Based Deliverable, or otherwise use any Evidence-Based
                  Deliverable for the benefit of any third party, except as explicitly
                  permitted in the Agreements.
                </li>
                <li>
                  <strong>Submissions. </strong>You understand and agree that Atropos’s
                  ability to provide the Services is dependent on your timely provision of
                  relevant data and other materials and you agree to provide the same to
                  Atropos in a timely manner and in the format as required by Atropos.
                  Atropos is not liable for any delay or failure of performance to the
                  extent caused by your delay in performing or failure to perform any of
                  your obligations under these Terms, including your failure to provide
                  the information necessary to generate an Evidence-Based Deliverable.
                </li>
                <li>
                  <strong>User Responsibilities. </strong>Except as permitted under the
                  Agreements or as required by law, you will not, and will not permit or
                  encourage anyone else, to:
                  <ol type="a">
                    <li>
                      Access or use the Services for purposes other than as permitted in
                      the Agreements; use the Services for unlawful purposes or in a
                      manner that violates any law or regulation; or commit fraud or
                      falsify information in connection with any use of the Services;
                    </li>
                    <li>
                      Except as expressly permitted under the Agreements, sell, license,
                      rent, distribute, disseminate, or publish the Services, or any part
                      thereof, or prepare any derivative works of or otherwise modify the
                      Services;
                    </li>

                    <li>
                      Obscure, remove, or alter any copyright notice or other intellectual
                      property right markings, designations, or notices in or on the
                      Services;
                    </li>

                    <li>
                      Attempt to identify, including by inference, any individual who is
                      the subject of any Evidence-Based Deliverable or the source of any
                      data included in the Platform;
                    </li>

                    <li>
                      Use the Services to develop, create, produce, enhance, or add to any
                      database; develop, assist in developing, or have developed on your
                      behalf or any other person software, technology, or services that
                      compete with or are substantially similar to the Services; access,
                      use, or analyze the Services for any purpose that is to Atropos’s
                      detriment or commercial disadvantage; or use the Services in a
                      manner that infringes, violates, or misappropriates Atropos’s or any
                      third party’s rights, including intellectual property rights and
                      privacy rights;
                    </li>

                    <li>
                      Except as expressly permitted in the Agreements, use the Services
                      for publicity purposes;
                    </li>

                    <li>
                      Resell or redistribute the Services or any Evidence-Based
                      Deliverable (or any portion thereof or derivative work thereof) to
                      any other party on a standalone basis;
                    </li>

                    <li>
                      Use the Services for any for any invasive, infringing, defamatory or
                      fraudulent purpose (for example, this may include phishing, creating
                      a pyramid scheme or mirroring a website);
                    </li>

                    <li>
                      Distribute viruses, worms, Trojan horses, corrupted files, hoaxes,
                      or other items of a destructive or deceptive nature;
                    </li>

                    <li>
                      Interfere with the use of the Services, or the equipment used to
                      provide the Services;
                    </li>

                    <li>
                      Disable, interfere with or circumvent any aspect of the Services;
                    </li>

                    <li>
                      Generate, distribute, publish or facilitate unsolicited mass email,
                      promotions, advertisings or other solicitations (“spam”);
                    </li>

                    <li>
                      License, sublicense, sell, resell, transfer, assign, distribute, use
                      as a service bureau or timeshare, or otherwise commercially exploit
                      or make the Services available to any third party in any way (except
                      as expressly permitted in the Agreements);
                    </li>

                    <li>
                      Modify, disassemble or make derivative works based upon the Services
                      or otherwise attempt to derive source code or other trade secrets
                      from the Services; reverse engineer or decompile the Services to
                      reach the source code of your Services; or
                    </li>

                    <li>Access the Services if you are a competitor of Atropos.</li>
                  </ol>
                </li>

                <li>
                  <strong>Customer Data. </strong>In accordance with the Agreements, you
                  agree to obtain and maintain in effect all permissions, consents, and
                  authorizations as required by applicable law with respect to all data
                  and information that you submit or otherwise provide to the Services.
                  Except as provided in the Agreements, you agree to not provide any
                  information that identifies or could be used to identify any natural
                  person.
                </li>
                <li>
                  <strong>Protected Health Information. </strong>
                  Unless explicitly permitted in the Agreements, you agree not to provide,
                  transmit, otherwise disclose to, nor make accessible to the Services or
                  to Atropos, any personal information or other information that
                  identifies or could be used, either alone or in combination with other
                  information, to identify any patient or other natural person that is
                  subject to federal or state data protection laws, including without
                  limitation, any Protected Health Information (as defined by the Health
                  Insurance Portability and Accountability Act of 1996 and the rules and
                  regulations promulgated thereunder). To the extent that you are
                  authorized by the Agreements to transmit such identifiable information
                  to Atropos, you agree to execute a business associate agreement and/or
                  other privacy agreement with Atropos as required by applicable law.
                </li>
                <li>
                  <strong>Privacy Policy. </strong>
                  Atropos’s collection, use, and disclosure of data and information in
                  connection with the Services will be in accordance with the Agreements
                  and the{' '}
                  <a href="https://www.atroposhealth.com/privacy-policy" target="_blanck">
                    Atropos Privacy Policy
                  </a>
                  , as applicable.
                </li>
                <li>
                  <strong>“Feedback”. </strong>
                  You may provide suggestions, comments, or other feedback (collectively,
                  “Feedback”) to Atropos with respect to the Services. You grant Atropos
                  an as-is, non-exclusive, world-wide, perpetual, royalty-free, fully paid
                  license to use the Feedback for any purpose, including the development
                  and exploitation of its current and future products and services,
                  without obligation of any kind to you. Feedback is voluntary and is
                  provided entirely “as-is”, without warranties of any kind. All use of
                  the Feedback is at Atropos’s sole risk and liability.
                </li>
                <li>
                  <strong>Beta Services. </strong>
                  Atropos may make new features of the Services available to you.
                  Notwithstanding any other provision herein, Services released as beta,
                  pilot, limited release, non-production or evaluation (“
                  <strong>Beta Features</strong>
                  ”) are made available to you “as-is” without any warranty, support,
                  maintenance or other obligation of any kind. Atropos may terminate
                  access to, or use of, a Beta Feature at any time.
                </li>
                <li>
                  <strong>Atropos Trademarks. </strong>
                  Except as may be required by applicable law or regulation, you will not,
                  without Atropos’s prior written consent, use the names, logos,
                  tradenames, trademarks, or service marks of Atropos or its Affiliates.
                </li>
                <li>
                  <strong>Termination of your Use of the Services. </strong>
                  Your use of the Services is subject to any term(s) set forth in the
                  Agreements, and the policies and procedures of the organization with
                  which you are affiliated. Atropos may immediately terminate your access
                  to the Services upon any termination or expiration of the Agreements.
                </li>

                <li>
                  <strong>Changes to Terms. </strong>Atropos may modify these Terms (an “
                  <strong>Update</strong>”) at any time and in our sole discretion, and
                  such Updates will be effective immediately. If we make Updates to these
                  Terms, we will change the “last updated” date above, which shall
                  constitute, to the extent permissible, any notice required by applicable
                  law.
                </li>
              </ol>
            </div>
            <div className="d-md-flex flex-justify-between mt-3">
              <div className="flex-self-center mb-md-0 mb-3">
                Agree to our Terms of Service?
              </div>
              <div className="d-flex flex-self-center tos-switch">
                <div>Decline</div>

                <Switch
                  checkedIcon={false}
                  onColor="#000000"
                  offColor="#F7F7F7"
                  height={20}
                  width={30}
                  uncheckedIcon={false}
                  onChange={handleSwitchChange}
                  checked={tosSwitch}
                  className="flex-self-center mx-3"
                  id={'tos-switch'}
                />
                <div>Accept</div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-justify-end mt-3">
            <ButtonCustom
              cssClass={`back-btn py-3 px-3 mr-3 width-50`}
              onClick={onClickOfTosBackButton}
              buttonAction={'Onboarding TOS back button clicked'}
              id={'btn-onboarding-tos-back-button'}
            >
              Back
            </ButtonCustom>
            <ButtonCustom
              cssClass={`gradient-btn py-3 px-3 width-50`}
              onClick={onClickOfTosConfirmBtn}
              id={'btn-onboarding-tos-confirm-button'}
              buttonAction={'Onboarding TOS confirm clicked'}
              actionLabel={'Onboarding information submitted'}
            >
              {isLoading ? 'Loading...' : 'Confirm and continue'}
            </ButtonCustom>
          </div>
          {!!updateProfileError && userData?.subscription_information !== null ? (
            <div className="d-flex onboarding-error-container mt-4">
              <div className="mr-3 flex-self-center">
                <RiErrorWarningFill className="d-block" />
              </div>
              <div className="flex-self-center">{updateProfileError}</div>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default TermsOfServices;
