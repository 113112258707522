import React from 'react';

const RadioButton = ({
  idValue,
  classNameValue,
  buttonName,
  checked,
  onClick,
  name,
  value,
  desc,
  id,
}) => {
  const check = checked;
  return (
    <div className={`radio-input ${classNameValue ? classNameValue : ''} `}>
      <label>
        <input
          id={idValue}
          type="radio"
          name={name}
          value={value}
          onClick={onClick}
          className="mr-3"
          checked={check}
          id={id}
          readOnly
        />
        {buttonName}
        {name === 'questionType' ? (
          <div className="question-desc pl-5 mt-2">{desc}</div>
        ) : (
          ''
        )}
      </label>
    </div>
  );
};

export default RadioButton;
