import React, { useState, useEffect, useRef } from 'react';
import 'react-tabs/style/react-tabs.css';
import TextInput from 'components/form-input/TextInput';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  orderPrognostogram,
  clearOrderFormData,
  modifyPrognostogramOrder,
  resetOrderIndexData,
  uploadOrderDocument,
  resetUploadDocumentData,
  getMotivations,
  removeUploadedFile,
  clearUploadDocumentsList
} from 'redux/modules/orderDetails/actions';
import { dateFormatyyyymmdd, dateFormatmmddyyyy } from '../utils/dateFormats';
import { getRequesters } from 'redux/modules/userProfile/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getRawToken, validateEmail } from '../utils/validations';
import urlConstantsEnum from '../constants/enum/urlConstantsEnum';
import { useHistory, useLocation } from 'react-router-dom';
import AuthLoader from 'components/AuthLoader';
import OrderPrognostogramHeader from 'components/OrderPrognostogramHeader';
import { Strings } from '../constants/Strings';
import CustomModal from '../components/CustomModal';
import { VscChromeClose } from 'react-icons/vsc';
import TextArea from 'components/form-input/TextArea';
import { ButtonCustom, ButtonLink } from 'components/form-input/Button';
import { INPUT_SIZE, PICOT_QUESTION_LINK } from 'constants/Constants';
import { ImInfo } from 'react-icons/im';
import Switch from 'react-switch';
import { HiOutlineChevronRight, HiOutlineChevronDown } from 'react-icons/hi';
import Checkbox from 'components/form-input/Checkbox';
import orderFormErrorStr from 'constants/errorStrings';
import Cookies from 'js-cookie';
import { MdError } from 'react-icons/md';
import { getSubscriptionDataset } from 'redux/modules/userSubscription/actions';
import {
  getPhysicianInstituteList,
  getUsersConfig,
} from 'redux/modules/userProfile/actions';
import { AiFillDelete } from "react-icons/ai";

function motivationRequiresPatientData(providerMotivation) {
  return providerMotivation && providerMotivation.label === 'Patient Care';
}

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

const SinglePageOrderRequest = () => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  let history = useHistory();
  const ref = useRef(null);
  const dataSourceDivRef = useRef(null);
  const refConfidential = useRef(null);
  const refMotivation = useRef(null);
  const refRequester = useRef(null);
  const refPHI = useRef(null);

  const location = useLocation();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [showTooltip, setShowTooltip] = useState(false);
  const [showRequesterTooltip, setShowRequesterTooltip] = useState(false);
  const [showMotivationTooltip, setShowMotivationTooltip] = useState(false);
  const [showPHIToolTip, setShowPHITooltip] = useState(false);

  const [showTooltipConfidential, setShowTooltipConfidential] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [formErrors, setFormErrors] = useState('');
  const [errorsObject, setFormErrorsObject] = useState({});
  const [selectedPreviousRequestor, setSelectedPreviousRequestor] = useState(null);
  const [isMaxDocumentUploaded, setIsMaxDocumentUploaded] = useState(false);

  const { userData, updateProfileError, requesters, getRequestersError, usersConfig } =
    useSelector(({ userProfile }) => userProfile);

  const {
    isOrderLoading,
    orderPrognostogramError,
    orderFormData,
    orderScopeData,
    uploadedDocumentInfo,
    isUploadingDocument,
    getDocumentUploadError,
    fetchedDocument,
    isFetchingDocumentStarted,
    getDocumentError,
    motivations,
    uploadedFilesList
  } = useSelector(({ orderDetails }) => orderDetails);

  function getInitialMotivation(motivations) {
    if (motivations && motivations.length) {
      const presentMotivations = motivations.filter((motivation) => motivation.value);
      if (presentMotivations.length) {
        const { category } = presentMotivations[0];
        return { label: category };
      }
    }
    return null;
  }

  const [selectedProviderMotivation, setSelectedProviderMotivation] = useState(
    getInitialMotivation(orderFormData?.motivations ?? motivations),
  );

  const { datasetList } = useSelector(({ userSubscription }) => userSubscription);
  const {
    first_name = '',
    email = '',
    last_name = '',
    institute = '',
    guid = '',
  } = userData || {};

  const [isDocumentUploadClicked, setIsDocumentUploadClicked] = useState(false);
  const [isShowPhiModal, setIsShowPhiModal] = useState(false);
  const [documentFileName, setDocumentFileName] = useState(
    orderFormData?.documentFileName ? orderFormData?.documentFileName : '',
  );
  const [documentFile, setDocumentFile] = useState();
  const [phiDisclaimer, setPhiDisclaimer] = useState(
    orderFormData?.phiDisclaimer ? orderFormData?.phiDisclaimer : false,
  );
  const [documentError, setDocumentError] = useState('');

  const [filePath, setFilePath] = useState('');

  const [dataTypeValue, setDatatypeValue] = useState(
    orderFormData?.data_source ? orderFormData?.data_source : '',
  );
  const [sharingValue, setSharingValue] = useState(
    orderFormData?.sharing_type ? orderFormData?.sharing_type : 'Library',
  );
  const urgencyTypeList = ['Not Urgent', 'Urgent'];
  const [urgencyType, setUrgencyType] = useState(
    orderFormData?.urgencyType
      ? orderFormData?.urgencyType === true
        ? 'Urgent'
        : 'Not Urgent'
      : 'Not Urgent',
  );
  const [patientBirthDate, setPatientBirthDate] = useState(
    orderFormData?.patient?.birth_date
      ? dateFormatmmddyyyy(orderFormData?.patient?.birth_date)
      : '',
  );
  const [patientDatePickerIsOpen, setPatientDatePickerIsOpen] = useState(false);
  const [ShouldShowBirthdayError, setShouldShowBirthdayError] = useState(false);
  const [requestOnBehalf, setRequestOnBehalf] = useState(false);
  const [isShowDataSetDropDown, setShowDataSetDropDown] = useState(false);
  const [isConfidential, setConfidential] = useState(
    orderFormData?.sharing_type
      ? orderFormData?.sharing_type === 'Personal'
        ? true
        : false
      : false,
  );

  let initialState = {
    firstName: {
      value: orderFormData?.requester?.first_name
        ? orderFormData.requester.first_name
        : '',
      isValid: true,
      errorText: orderFormErrorStr.firstName,
    },
    lastName: {
      value: orderFormData?.requester?.last_name ? orderFormData.requester.last_name : '',
      isValid: true,
      errorText: orderFormErrorStr.lastName,
    },
    patientLastName: {
      value: orderFormData?.patient?.last_name ? orderFormData?.patient?.last_name : '',
      isValid: true,
      errorText: orderFormErrorStr.patientLastName,
    },
    newEmail: {
      value: orderFormData?.requester?.email ? orderFormData.requester?.email : '',
      isValid: true,
      errorText: orderFormErrorStr.email,
    },
    questionText: {
      value: orderFormData?.clinicalQuestion ? orderFormData.clinicalQuestion : '',
      length: orderFormData?.clinicalQuestion
        ? orderFormData?.clinicalQuestion.length
        : 0,
    },
    population: {
      value: orderFormData?.population ? orderFormData.population : '',
      length: orderFormData?.population ? orderFormData?.population.length : 0,
    },
    intervention: {
      value: orderFormData?.intervention ? orderFormData.intervention : '',
      length: orderFormData?.intervention ? orderFormData?.intervention.length : 0,
    },
    control: {
      value: orderFormData?.control ? orderFormData.control : '',
      length: orderFormData?.control ? orderFormData?.control.length : 0,
    },
    outcome: {
      value: orderFormData?.outcome ? orderFormData.outcome : '',
      length: orderFormData?.outcome ? orderFormData?.outcome.length : 0,
    },
    timeframe: {
      value: orderFormData?.timeframe ? orderFormData.timeframe : '',
      length: orderFormData?.timeframe ? orderFormData?.timeframe.length : 0,
    },
    picotSwitch: {
      value: orderFormData?.picotSwitch ? orderFormData.picotSwitch : false,
    },
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: '8',
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '4px',
      boxShadow: 'none',
      border: '1px solid rgba(26, 26, 26, 0.5)',
      padding: '5px 0px 5px 16px',
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#000',
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#000',
      backgroundColor: state.isFocused ? '#d8eeee' : '#fff',
      ':active': {
        backgroundColor: '#0D3F3C',
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    },
  };

  const motivationCustomStyles = {
    ...customStyles,
    control: (provided, state) => ({
      ...provided,
      borderRadius: '4px',
      boxShadow: 'none',
      border:
        errorsObject.motivation && !selectedProviderMotivation
          ? `1px solid red`
          : '1px solid rgba(26, 26, 26, 0.5)',
      padding: '5px 0px 5px 16px',
    }),
  };

  const [formState, setFormState] = useState(initialState);

  const handleCheck = () => {
    mixpanel.track(
      'Confidential input ' + !isConfidential ? 'checked' : 'unchecked' + ' event',
    );
    setConfidential(!isConfidential);
  };

  const handleChange = (value, name) => {
    setFormErrors('');
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], value: value.trim() },
    }));
  };

  const handleEmailChange = (value) => {
    setFormErrors('');
    setFormState((formState) => ({
      ...formState,
      newEmail: { ...formState.newEmail, value: value },
    }));
    mixpanel.track('Email changed event', {
      selected_email: value,
    });
  };

  const isFormValid = () => {
    let errorText = '';
    const noFirstName = !formState?.firstName.value;
    const noLastName = !formState?.lastName.value;
    const noEmail = !formState?.newEmail.value;
    const noPatientLastName = !formState?.patientLastName.value;
    const noPatientBirthDate = !patientBirthDate;

    const userSelectedMotivation =
      selectedProviderMotivation && selectedProviderMotivation.label;

    const errorsObject = {};

    if (requestOnBehalf && noFirstName) {
      errorsObject.firstName = Strings.orderFirstNameError;
    }
    if (requestOnBehalf && noLastName) {
      errorsObject.lastName = Strings.orderLastNameError;
    }

    if (requestOnBehalf && !validateEmail(formState?.newEmail.value)) {
      errorsObject.email = orderFormErrorStr.email;
    }
    if (
      motivationRequiresPatientData(selectedProviderMotivation) &&
      usersConfig?.configuration?.prognostogram?.motivations?.patient_care
    ) {
      const date = new Date(patientBirthDate);
      if (noPatientLastName) {
        errorsObject.patientLastName = Strings.patientLastNameError;
      }
      if (noPatientBirthDate && !isValidDate(date)) {
        errorsObject.patientBirthDate = Strings.patientBirthDateError;
      }
    }
    if (!userSelectedMotivation) {
      errorsObject.motivation = Strings.motivationError;
    }

    setFormErrorsObject(errorsObject);

    errorText = errorsObject ? Object.values(errorsObject)[0] : '';

    setFormErrors(errorText);
    return !errorText;
  };

  const submitOrder = async () => {
    const token = await getIdTokenClaims();
    if (formState.questionText.value && isFormValid() && !isUploadingDocument) {
      dispatch(resetOrderIndexData());

      // var documentPath = '';
      // if (uploadedDocumentInfo?.key_path) {
      //   documentPath = uploadedDocumentInfo?.key_path;
      // } else if (
      //   orderFormData?.previous_order_guid &&
      //   orderFormData?.documentKeyPath !== ''
      // ) {
      //   documentPath = orderFormData?.documentKeyPath;
      // } else {
      //   documentPath = '';
      // }

      let documentPaths = [];
      if(uploadedFilesList && uploadedFilesList.length > 0){
        uploadedFilesList.forEach(file => {
          const obj = {
            'key_path':file.key_path,
            'phi_disclaimer':file.phi_disclaimer,
            'is_uploaded':file.is_uploaded
          }
          documentPaths.push(obj);
        })
      }

      const order = {
        user: {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
        },
        patient:
          motivationRequiresPatientData(selectedProviderMotivation) &&
          usersConfig?.configuration?.prognostogram?.motivations?.patient_care
            ? {
                last_name: formState.patientLastName.value,
                birth_date: patientBirthDate ? dateFormatyyyymmdd(patientBirthDate) : '',
              }
            : undefined,
        institute: {
          institute_name: userData.institute.name,
          institute_guid: userData.institute.guid,
        },
        order: {
          clinical_questions: formState.questionText.value,
          question_title: formState.questionText.value,
          population: formState.population.value,
          intervention: formState.intervention.value,
          control: formState.control.value,
          outcome: formState.outcome.value,
          timeframe: formState.timeframe.value,
          picot_status: formState.picotSwitch.value,
          data_source:
            dataTypeValue !== '' ? dataTypeValue.split(',') : ['Instituitional'],
          sharing_type: sharingValue,
          confidential: isConfidential,
          is_urgent: urgencyType === 'Urgent' ? true : false,
          documents: documentPaths,
          phi_disclaimer: documentFileName !== '' ? phiDisclaimer : null,
          previous_order_guid: orderFormData?.previous_order_guid,
          motivations: Object.fromEntries(
            motivations.map(({ category, value }) => [
              category,
              selectedProviderMotivation
                ? selectedProviderMotivation.label === category
                  ? true
                  : false
                : false,
            ]),
          ),
          ordered_for: requestOnBehalf
            ? {
                first_name: formState.firstName.value,
                last_name: formState.lastName.value,
                email: formState.newEmail.value,
              }
            : undefined,
        },
      };

      const isReorder =
        orderFormData?.previous_order_guid &&
        orderFormData?.orderStatus === 'Pending' &&
        orderFormData?.isEditOrder;


      if (isReorder) {
        dispatch(
          modifyPrognostogramOrder({
            params: order,
            orderGuid: orderFormData?.previous_order_guid,
            token: token ? token.__raw : '',
            navigateToDashboard: navigateToDashboard,
          }),
        );
      } else {
        dispatch(
          orderPrognostogram({
            params: order,
            csrfToken: Cookies.get('csrftoken'),
            token: token ? token.__raw : '',
            navigateToDashboard: navigateToDashboard,
          }),
        );
      }
    }
  };

  const navigateToDashboard = () => {
    dispatch(clearOrderFormData());
    history.push(urlConstantsEnum.ORDER_SUBMITTED);
  };
  useEffect(() => {}, [dispatch]);

  !!updateProfileError ? setShowLoader(false) : '';

  const onInputChange = (e, name) => {
    if (e.target.value.length <= INPUT_SIZE) {
      setFormState((formState) => ({
        ...formState,
        [name]: {
          ...formState[name],
          value: e.target.value,
          length: e.target.value.length,
        },
      }));
    } else {
      e.preventDefault();
    }
  };

  const handleSwitchChange = (checked) => {
    mixpanel.track('PICOT switch clicked event', {
      is_picot_option_selected: checked,
    });
    setFormState((formState) => ({
      ...formState,
      picotSwitch: {
        value: checked,
      },
    }));
  };

  const onClickOfBackBtn = () => {
    dispatch(clearUploadDocumentsList())
    history.push(
      orderScopeData?.scopeTabIndex === 1
        ? urlConstantsEnum.LIBRARY_DASHBOARD
        : urlConstantsEnum.DASHBOARD,
    );
  };

  const handleDocumentUpload = () => {
    mixpanel.track(
      'Document upload option ' + !isDocumentUploadClicked
        ? 'opened'
        : 'closed' + ' event',
    );
    if (!isDocumentUploadClicked) {
      setIsDocumentUploadClicked(true);
    } else {
      setIsDocumentUploadClicked(false);
    }
  };

  useEffect(() => {
    const getInstituteList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getPhysicianInstituteList({
          params: {
            institute_name: '',
          },
          token: token ? token.__raw : '',
        }),
      );
    };
    const getDatasetList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getSubscriptionDataset({
          token: token ? token.__raw : '',
        }),
      );
    };
    getDatasetList();
    getInstituteList();
  }, [dispatch]);

  const handleDocumentInputUpload = (e) => {
    if (e.target.files[0]) {
      var supportedExtensions = /(\.docx|\.pdf|\.csv|\.xlsx|\.pptx)$/i; //supported extensions
      var maxfilesize = 1024 * 1024 * 25; //Allowing 25MB
      var filesize = e.target.files[0].size;
      if (filesize > maxfilesize) {
        setDocumentError(Strings.documentSizeError);
      } else if (!supportedExtensions.exec(e.target.files[0].name)) {
        setDocumentError(Strings.documentExtensionError);
      } else {
        setDocumentError('');
        setIsShowPhiModal(true);
        setDocumentFile(e.target.files[0]);
        setDocumentFileName(e.target.files[0].name);
      }
    } else {
      setIsShowPhiModal(false);
      resetDocument();
    }
  };

  const resetDocument = () => {
    setDocumentFileName('');
    setDocumentFile(null);
    setDocumentError('');
  };

  const onClickOfDeleteDocument = () => {
    dispatch(resetUploadDocumentData());
    resetDocument();
  };

  const OnClickOfPhiModalClose = () => {
    setIsShowPhiModal(false);
    setPhiDisclaimer(false);
    if (orderFormData?.previous_order_guid && orderFormData?.documentFileName !== '') {
      setDocumentFileName(orderFormData?.documentFileName);
    } else {
      resetDocument();
    }
  };

  const OnClickOfPhiModalConfirm = () => {
    setIsShowPhiModal(false);
    setPhiDisclaimer(true);
    uploadDocument(true);
  };

  const uploadDocument = async (phiDisclaimerStatus = false) => {
    var data = new FormData();
    data.append('file', documentFile);
    const token = await getIdTokenClaims();
    if (token) {
      dispatch(
        uploadOrderDocument({
          token: getRawToken(token),
          params: data,
          tokenExpiredCallback: redirectToLogin,
          fileDetails:{
            file:documentFile,
            phiDisclaimerStatus
          }
        }),
      );
    } else {
      redirectToLogin();
    }
  };

  const getDocument = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      getOrderDocument({
        token: token ? token.__raw : '',
        guid: orderFormData?.previous_order_guid,
        tokenExpiredCallback: redirectToLogin,
      }),
    );
  };

  const redirectToLogin = () => {
    history.push(urlConstantsEnum.LOGIN);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowTooltip(false);
      mixpanel.track('PICOT tooltip closed event');
    }
    if (refConfidential.current && !refConfidential.current.contains(event.target)) {
      setShowTooltipConfidential(false);
      mixpanel.track('Confidential tooltip closed event');
    }
    if (refMotivation.current && !refMotivation.current.contains(event.target)) {
      setShowMotivationTooltip(false);
      mixpanel.track('Motivation tooltip closed event');
    }
    if (refRequester.current && !refRequester.current.contains(event.target)) {
      setShowRequesterTooltip(false);
      mixpanel.track('Requester tooltip closed event');
    }
    if (refPHI.current && !refPHI.current.contains(event.target)) {
      setShowPHITooltip(false);
      mixpanel.track('Motivation tooltip closed event');
    }
  };

  const onCancelRequest = () => {
    dispatch(resetOrderIndexData());
    dispatch(resetUploadDocumentData());
    dispatch(clearUploadDocumentsList());
    if (location?.state?.isFromSearch) {
      history.push({
        pathname:
          orderScopeData?.scopeTabIndex === 1
            ? urlConstantsEnum.LIBRARY_SEARCH_RESULT
            : urlConstantsEnum.SEARCH_RESULT,
        state: {
          searchQuery: location?.state?.searchText,
        },
      });
    } else {
      history.push(
        orderScopeData?.scopeTabIndex === 1
          ? urlConstantsEnum.LIBRARY_DASHBOARD
          : urlConstantsEnum.DASHBOARD,
      );
    }
  };

  const setAdvacedSettingBlock = () => {
    mixpanel.track(
      'Advanced setting option ' + !isButtonClicked ? 'opened' : 'closed' + ' event',
    );
    if (!isButtonClicked) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
  };

  const onClickOfUrgencyButton = (e) => {
    mixpanel.track('Urgency of request options clicked', {
      selected_urgency_value: e.target.value,
    });
    setUrgencyType(e.target.value);
  };

  const getUrgencyOfRequestList = () => {
    const listItems = urgencyTypeList?.map((source, index) => (
      <div className={`radio-input d-flex flex-self-center`} key={index + '-' + source}>
        <label className="d-flex flex-self-center">
          <input
            id={'not-urgent'}
            type="radio"
            name={'urgencyList'}
            value={urgencyTypeList[index]}
            onClick={onClickOfUrgencyButton}
            className="mr-3"
            checked={urgencyType === source ? true : false}
            readOnly
          />
          <div className="d-flex flex-self-center flex-items-center">
            <span>{source} &nbsp;</span> {source === 'Urgent' ? '< ' : '> '}48hrs
          </div>
        </label>
      </div>
    ));
    return (
      <div className="mb-4 urgency-container">
        <div className="advanced-setting-title mb-2">Urgency of Request</div>
        <div className="d-md-flex">{listItems}</div>
      </div>
    );
  };

  const handleDateChange = (date) => {
    setFormErrors('');
    setPatientBirthDate(date);
    setPatientDatePickerIsOpen(false);
  };

  const validateBirthdate = (date) => {
    if (date !== '' && date !== null) {
      var settedDate = new Date(date);
      var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      if (settedDate > currentDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const onBlurOfDate = (date) => {
    if (date && !validateBirthdate(date)) {
      setShouldShowBirthdayError(true);
      setPatientBirthDate('');
    }
  };

  const onFocusOfDate = () => {
    setShouldShowBirthdayError(false);
  };

  useEffect(() => {
    if (fetchedDocument !== undefined && fetchedDocument !== '') {
      window.open(fetchedDocument);
      dispatch(resetOrderDocument());
    }
  }, [fetchedDocument]);

  useEffect(() => {
    if (orderFormData?.requester !== undefined) {
      if (Object.keys(orderFormData?.requester).length !== 0) {
        setRequestOnBehalf(orderFormData?.requester);
      }
    }
  }, [orderFormData]);

  useEffect(() => {
    var dataSourceValues = dataTypeValue !== '' ? dataTypeValue.split(',') : [];
    if (dataSourceValues.length === 0) {
      if (datasetList) {
        dataSourceValues.push(datasetList[0].name);
        setDatatypeValue(dataSourceValues.join());
      }
    }
  }, [datasetList, dataTypeValue]);

  const setDataSourceChecked = (value) => {
    if (dataTypeValue) {
      var dataSourceValues = dataTypeValue.split(',');
      var index = dataSourceValues.indexOf(value);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleDataSourceChange = (e) => {
    var dataSourceValues = dataTypeValue !== '' ? dataTypeValue.split(',') : [];
    datasetList?.map((source) => {
      if (e.target.value === source.name) {
        if (e.target.checked) {
          dataSourceValues.push(e.target.value);
        } else {
          var index = dataSourceValues.indexOf(e.target.value);
          if (index > -1) {
            dataSourceValues.splice(index, 1);
          }
        }
      }
    });
    mixpanel.track('Data source selected event', {
      idata_source_selected_value: dataSourceValues.join(),
    });
    setDatatypeValue(dataSourceValues.join());
  };

  const getDataSourceList = () => {
    const listItems = datasetList?.map((source, index) => (
      <li
        key={index + '-' + source.name}
        className="d-flex flex-content-center my-3 color-black li-list-specialty"
      >
        <Checkbox
          buttonName={source.name}
          onChange={handleDataSourceChange}
          name={'dataSource'}
          value={datasetList[index].name}
          checked={setDataSourceChecked(source.name)}
          id={'checkbox-data-source-selection'}
        />
      </li>
    ));
    return <ul className="">{listItems}</ul>;
  };

  useEffect(() => {
    function handler(event) {
      if (!dataSourceDivRef.current?.contains(event.target)) {
        setShowDataSetDropDown(false);
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, []);

  useEffect(() => {
    const getSeedMotivations = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getMotivations({
          token: getRawToken(token),
        }),
      );
    };

    getSeedMotivations();
  }, []);

  const getUserRequesters = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      getRequesters({
        token: getRawToken(token),
        guid,
      }),
    );
  };

  const onClickOfDataSource = () => {
    if (
      orderFormData?.previous_order_guid &&
      orderFormData?.orderStatus === 'Pending' &&
      orderFormData?.isEditOrder
    ) {
      setShowDataSetDropDown(false);
    } else {
      mixpanel.track('Data set dropdown clicked event');
      setShowDataSetDropDown(!isShowDataSetDropDown);
    }
  };

  useEffect(() => {
    const getUsersConfigData = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getUsersConfig({
          token: getRawToken(token),
        }),
      );
    };
    getUsersConfigData();
  }, [dispatch, getIdTokenClaims]);

  const handleRemoveDocument = (index) => {
    if(isMaxDocumentUploaded){
      setIsMaxDocumentUploaded(false)
    }
    dispatch(removeUploadedFile(index))
  }

  const handleMaxDocUploadErr = (e) => {
    if(uploadedFilesList && uploadedFilesList.length == 5){
      e.preventDefault();
      setIsMaxDocumentUploaded(true);
    }
  }

  return isOrderLoading ? (
    <AuthLoader fullScreen={true} />
  ) : (
    <div className="order-container pb-3">
      <OrderPrognostogramHeader
        onClickOfBackBtn={onClickOfBackBtn}
        headerText={
          orderFormData?.previous_order_guid && orderFormData?.isEditOrder
            ? 'Edit Order'
            : orderFormData?.previous_order_guid && !orderFormData?.isEditOrder
            ? 'Modify and Reorder'
            : 'Request a Prognostogram'
        }
      />
      <CustomModal
        isOpen={isShowPhiModal}
        handleClose={OnClickOfPhiModalClose}
        cssClass="white-overlay"
        modalname="PHI modal"
        overlayCssClass={'phi-modal-overlay'}
      >
        <div className="email-modal p-5 delete-modal phi-modal">
          <div className="profile-sub-header mt-4 mb-2">{Strings.phiModalText1}</div>
          <div className="d-md-flex flex-justify-end">
            <ButtonCustom
              cssClass="plain-btn mt-5 px-4"
              onClick={() => OnClickOfPhiModalClose()}
              buttonAction={'Cancel PHI button clicked'}
              actionLabel={'Cancel document contains PHI'}
              id={'btn-cancel-phi-button'}
            >
              Cancel
            </ButtonCustom>
            <ButtonCustom
              cssClass="btn-primary mt-2 mt-md-5 px-4 ml-md-3"
              onClick={() => OnClickOfPhiModalConfirm()}
              buttonAction={'PHI confirm button clicked'}
              actionLabel={'Confirm document does not have PHI'}
              id={'btn-confirm-phi-button'}
            >
              Confirm
            </ButtonCustom>
          </div>
        </div>
      </CustomModal>
      <div className="order-section-container clinical-question-container mb-3 pb-5">
        <div className="user-details d-md-flex mb-3">
          <div className="d-flex col-md-4 col-12">
            <div className="col-6">
              <div className="user-details-label">First Name</div>
              <div className="user-details-value">{first_name}</div>
            </div>
            <div className="col-6">
              <div className="user-details-label">Last Name</div>
              <div className="user-details-value">{last_name}</div>
            </div>
          </div>
          <div className="d-md-flex col-md-8 col-12">
            <div className="col-md-6 col-12 user-details-wrapper">
              <div className="user-details-label">Email</div>
              <div className="user-details-value">{email}</div>
            </div>
            <div className="col-md-6 col-12">
              <div className="user-details-label">Organization</div>
              <div className="user-details-value">{institute.name}</div>
            </div>
          </div>
        </div>

        <div className="mb-4 urgency-container">
          <div className="advanced-setting-title mb-3">
            Are you submitting on behalf of somebody else?
          </div>
          <div className="d-md-flex">
            <div className={`radio-input d-flex flex-self-center`}>
              <label className="d-flex flex-self-center">
                <input
                  id={'not-requester'}
                  type="radio"
                  name={'requestOnBehalf'}
                  value={false}
                  className="mr-3"
                  checked={!requestOnBehalf}
                  onChange={() => {
                    const resetRequestFor = () => {
                      setSelectedPreviousRequestor(null);
                      setFormState((formState) => ({
                        ...formState,
                        firstName: '',
                        lastName: '',
                        newEmail: '',
                      }));
                    };
                    resetRequestFor();
                    setRequestOnBehalf(false);
                  }}
                />
                <div className="d-flex flex-self-center flex-items-center">
                  <span>No</span>
                </div>
              </label>
            </div>
            <div className={`radio-input d-flex flex-self-center`}>
              <label className="d-flex flex-self-center">
                <input
                  id={'yes-requester'}
                  type="radio"
                  name={'requestOnBehalf'}
                  value={true}
                  className="mr-3"
                  checked={requestOnBehalf}
                  onChange={() => {
                    setRequestOnBehalf(true);
                    getUserRequesters();
                  }}
                />
                <div className="d-flex flex-self-center flex-items-center">
                  <span>Yes</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        {requestOnBehalf && (
          <div className="mb-4">
            <div className="advanced-setting-title mb-2 flex items-center">
              Prognostogram Requester
              <div className="d-flex">
                <div className="tooltip-container flex-self-center">
                  <div
                    style={{ lineHeight: '16px' }}
                    className={
                      showRequesterTooltip ? 'tooltip-box visible' : 'tooltip-box'
                    }
                    ref={refRequester}
                  >
                    Both yourself and the requester will be returned the Prognostogram and
                    be able to view it in the Portal under your Personal Tab.{' '}
                    <span className="tooltip-arrow" />
                  </div>
                  <div
                    className="tooltip-button d-flex"
                    id={'picot-tooltip'}
                    onMouseEnter={() => {
                      setShowRequesterTooltip(true);
                      mixpanel.track('PICOT tooltip opened event');
                    }}
                  >
                    <ImInfo />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-50 mb-3">
              {requesters && requesters.length ? (
                <>
                  <div className="input-label mb-2">Select Answer</div>
                  <Select
                    disabled={!requesters}
                    options={
                      requesters
                        ? requesters.map((onBehalfOf) => ({
                            label:
                              onBehalfOf.first_name +
                              ' ' +
                              onBehalfOf.last_name +
                              ' - ' +
                              onBehalfOf.email,
                            value: onBehalfOf,
                          }))
                        : []
                    }
                    value={
                      selectedPreviousRequestor
                        ? {
                            label:
                              'Previously submitted - ' +
                              selectedPreviousRequestor.first_name +
                              ' ' +
                              selectedPreviousRequestor.last_name,
                          }
                        : null
                    }
                    styles={customStyles}
                    onChange={(selectedLabel) => {
                      if (selectedLabel) {
                        const { first_name, last_name, _, email } = selectedLabel.value;
                        setSelectedPreviousRequestor({ first_name, last_name, email });
                        handleChange(first_name, 'firstName');
                        handleChange(last_name, 'lastName');
                        handleEmailChange(email);
                      } else {
                        setSelectedPreviousRequestor(null);
                        handleChange('', 'firstName');
                        handleChange('', 'lastName');
                        handleEmailChange('');
                      }
                    }}
                    placeholder={'Previously submitted'}
                    isClearable={true}
                    id={'select-onboardinng-organization'}
                    noOptionsMessage={({ inputValue: string }) =>
                      'No Previous Requesters'
                    }
                  />{' '}
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex mb-3">
              <div className="col-md-6 mr-2">
                <div className="input-label mb-2">First Name</div>
                <TextInput
                  isError={!!errorsObject.firstName && !formState.firstName.value}
                  errorText={errorsObject.firstName}
                  disabled={!!selectedPreviousRequestor}
                  value={formState.firstName.value || ''}
                  placeholder="E.g. Filipe"
                  isFloatingLabel={false}
                  onChange={(e) => handleChange(e.target.value, 'firstName')}
                  id={'textInput-onboardinng-first-name'}
                />
              </div>
              <div className="col-md-6 ml-2">
                <div className="input-label mb-2">Last Name</div>
                <TextInput
                  isError={!!errorsObject.lastName && !formState.lastName.value}
                  errorText={errorsObject.lastName}
                  disabled={!!selectedPreviousRequestor}
                  placeholder="E.g  Berger"
                  value={formState.lastName.value || ''}
                  onChange={(e) => handleChange(e.target.value, 'lastName')}
                  isFloatingLabel={false}
                  id={'textInput-onboardinng-last-name'}
                />
              </div>
            </div>

            <div className="col-md-6 pr-1">
              <div className="input-label mb-2">Email</div>
              <TextInput
                isError={!!errorsObject.email && !formState.newEmail.value}
                errorText={errorsObject.email}
                disabled={!!selectedPreviousRequestor}
                placeholder="Email"
                value={formState.newEmail.value}
                onChange={(e) => handleEmailChange(e.target.value)}
                isFloatingLabel={false}
                id={'textInput-email'}
              />
            </div>
          </div>
        )}
        <div className="mb-4">
          <div className="advanced-setting-title mb-3">
            What is the goal / motivation for this request?
            <div className="d-flex">
              <div className="tooltip-container flex-self-center">
                <div
                  style={{ lineHeight: '16px' }}
                  className={
                    showMotivationTooltip ? 'tooltip-box visible' : 'tooltip-box'
                  }
                  ref={refMotivation}
                >
                  If this Prognostogram is for an eConsult or Direct Patient Care, we will
                  ask to capture the patient’s last name and DOB.
                  <span className="tooltip-arrow" />
                </div>
                <div
                  className="tooltip-button d-flex"
                  id={'picot-tooltip'}
                  onMouseEnter={() => {
                    setShowMotivationTooltip(true);
                    mixpanel.track('PICOT tooltip opened event');
                  }}
                >
                  <ImInfo />
                </div>
              </div>
            </div>
          </div>
          <div className="input-label mb-2">Motivation</div>
          <div className="w-50 mb-3">
            <Select
              isDisabled={false}
              options={motivations?.map((motivation) => ({ label: motivation.category }))}
              value={selectedProviderMotivation}
              styles={motivationCustomStyles}
              onChange={(motivation) => {
                setFormErrors('');
                setSelectedProviderMotivation(motivation);
              }}
              placeholder={'Select Answer'}
              isClearable={true}
              id={'select-onboardinng-organization'}
              noOptionsMessage={({ inputValue: string }) =>
                'Please select a valid provider motivation'
              }
            />
            {errorsObject.motivation && !selectedProviderMotivation ? (
              <div style={{ color: 'red', fontSize: '11px', marginTop: '3px' }}>
                {errorsObject.motivation}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {selectedProviderMotivation &&
          motivationRequiresPatientData(selectedProviderMotivation) &&
          usersConfig?.configuration?.prognostogram?.motivations?.patient_care && (
            <div className="mb-4">
              <div className="advanced-setting-title mb-2">
                Patient’s Information
                <div className="d-flex">
                  <div className="tooltip-container flex-self-center">
                    <div
                      style={{ lineHeight: '16px' }}
                      className={showPHIToolTip ? 'tooltip-box visible' : 'tooltip-box'}
                      ref={refPHI}
                    >
                      We take your patient’s Protected Health Information (PHI) seriously
                      and have implemented infrastructure to comply with HIPAA and general
                      privacy practices. See more on our policy{' '}
                      <a href="https://www.atroposhealth.com/HIPAA">here</a>
                      <span className="tooltip-arrow" />
                    </div>
                    <div
                      className="tooltip-button d-flex"
                      id={'picot-tooltip'}
                      onMouseEnter={() => {
                        setShowPHITooltip(true);
                        mixpanel.track('PICOT tooltip opened event');
                      }}
                    >
                      <ImInfo />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pr-1 mb-4">
                <div className="input-label mb-2">Last Name</div>
                <TextInput
                  isError={
                    errorsObject.patientLastName && !formState.patientLastName.value
                  }
                  errorText={errorsObject.patientLastName}
                  value={formState.patientLastName.value}
                  placeholder="Last Name"
                  isFloatingLabel={false}
                  onChange={(e) => handleChange(e.target.value, 'patientLastName')}
                  id={'textInput-first-name'}
                />
              </div>

              <div className="input-label mb-1">Date of Birth</div>
              <div className="col-md-6 pr-1 mb-4">
                <TextInput
                  isError={
                    errorsObject.patientBirthDate &&
                    !isValidDate(new Date(patientBirthDate))
                  }
                  errorText={errorsObject.patientBirthDate}
                  value={patientBirthDate}
                  placeholder="MM/DD/YYYY"
                  isFloatingLabel={false}
                  className={`${ShouldShowBirthdayError ? 'border-error' : ''}`}
                  onFocus={() => {
                    setPatientDatePickerIsOpen(true);
                  }}
                  onChange={(e) => {
                    setPatientDatePickerIsOpen(false);
                    let value = e.target.value;
                    const numberOfSlashes = value.split('/').length - 1;

                    const needsSlashAfterDay =
                      value.replace(/[^0-9]/g, '').length == 2 && numberOfSlashes < 1;
                    const needSlashAfterMonth =
                      numberOfSlashes == 1 &&
                      value.split('/')[1].replace(/[^0-9]/g, '').length == 2;
                    if (
                      (needsSlashAfterDay || needSlashAfterMonth) &&
                      patientBirthDate.slice(-1) != '/'
                    ) {
                      value += '/';
                    }
                    handleDateChange(value);
                  }}
                  id={'datepicker-birthdate'}
                />
                {patientDatePickerIsOpen && (
                  <DatePicker
                    inline
                    className={`${ShouldShowBirthdayError ? 'border-error' : ''}`}
                    onChange={(date) => {
                      handleDateChange(date.toLocaleDateString('en-US'));
                    }}
                    id={'datepicker-birthdate-picker'}
                  />
                )}
              </div>

              {ShouldShowBirthdayError ? (
                <div className="color-error error-text">
                  {orderFormErrorStr.birthDate}
                </div>
              ) : (
                ''
              )}
            </div>
          )}

        <div className="d-flex flex-justify-between my-3 position-relative">
          <div className="d-flex flex-self-center position-relative">
            <span className="question-label">Your Question</span>
          </div>

          <div className="d-flex">
            <div className="d-flex picot-switch">
              <span className="flex-self-center picot-span d-flex">PICOT</span>
              <div className="tooltip-container flex-self-center">
                <div
                  className={showTooltip ? 'tooltip-box visible' : 'tooltip-box'}
                  ref={ref}
                >
                  <span className="font-bold">P</span>opulation,{' '}
                  <span className="font-bold">I</span>ntervention,{' '}
                  <span className="font-bold">C</span>ontrol,
                  <span className="font-bold">O</span>utcome,{' '}
                  <span className="font-bold">T</span>imeframe
                  <div
                    className="mt-2"
                    id={'picot-tooltip-option'}
                    onClick={() => {
                      setShowTooltip(false);
                      mixpanel.track('PICOT tooltip closed event');
                    }}
                  >
                    For more information on how to ask a well structured PICOT question,{' '}
                    <a
                      href={PICOT_QUESTION_LINK}
                      target="_blank"
                      rel="noreferrer"
                      id={'picot-question-link'}
                    >
                      click here
                    </a>
                  </div>
                  <span className="tooltip-arrow" />
                </div>
                <div
                  className="tooltip-button d-flex"
                  id={'picot-tooltip'}
                  onMouseEnter={() => {
                    setShowTooltip(true);
                    mixpanel.track('PICOT tooltip opened event');
                  }}
                >
                  <ImInfo />
                </div>
              </div>
              <Switch
                checkedIcon={false}
                onColor="#000000"
                offColor="#F7F7F7"
                height={20}
                width={30}
                uncheckedIcon={false}
                onChange={handleSwitchChange}
                checked={formState.picotSwitch.value}
                className="flex-self-center"
                id={'picot-switch'}
              />
            </div>
          </div>
        </div>
        <div className="study-protocol-text">
          If you have a study protocol that you would like us to emulate, please include
          or upload document below
        </div>
        {formState.picotSwitch.value ? (
          <div className="picot-container">
            <div className="picot-lable mb-2">Question title</div>
            <TextArea
              value={formState.questionText.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'questionText')}
              id={'textarea-question-text'}
            />
            <div className="text-count">
              {formState.questionText.length}/{INPUT_SIZE}
            </div>
            <div className="picot-lable mb-2">Population</div>
            <TextArea
              value={formState.population.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'population')}
              id={'textarea-population'}
            />
            <div className="text-count">
              {formState.population.length}/{INPUT_SIZE}
            </div>
            <div className="picot-lable mb-2">Intervention</div>
            <TextArea
              value={formState.intervention.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'intervention')}
              id={'textarea-intervention'}
            />
            <div className="text-count">
              {formState.intervention.length}/{INPUT_SIZE}
            </div>
            <div className="picot-lable mb-2">Control</div>
            <TextArea
              value={formState.control.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'control')}
              id={'textarea-control'}
            />
            <div className="text-count">
              {formState.control.length}/{INPUT_SIZE}
            </div>
            <div className="picot-lable mb-2">Outcome</div>
            <TextArea
              value={formState.outcome.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'outcome')}
              id={'textarea-outcome'}
            />
            <div className="text-count">
              {formState.outcome.length}/{INPUT_SIZE}
            </div>
            <div className="picot-lable mb-2">Timeframe</div>
            <TextArea
              value={formState.timeframe.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'timeframe')}
              id={'textarea-timeframe'}
            />
            <div className="text-count">
              {formState.timeframe.length}/{INPUT_SIZE}
            </div>
          </div>
        ) : (
          <div>
            <TextArea
              placeholder="Write your clinical question here.."
              value={formState.questionText.value}
              rows={14}
              onChange={(e) => onInputChange(e, 'questionText')}
              id={'textarea-clinical-question'}
            />
            <div className="text-count">
              {formState.questionText.length}/{INPUT_SIZE}
            </div>
          </div>
        )}
        {getUrgencyOfRequestList()}

        <div>
          <div className="advaced-settings-div mb-4">
            <div
              className="d-flex cursor-pointer"
              onClick={() => handleDocumentUpload()}
              id={'document-upload-option'}
            >
              <div className="advanced-setting-title">Document Upload</div>
              <div
                className={
                  !isDocumentUploadClicked
                    ? 'button-position right-arrow'
                    : 'button-position right-arrow rotate-button'
                }
              >
                <ButtonLink
                  buttonAction="Document Upload section clicked"
                  id={'document-upload-icon'}
                >
                  <HiOutlineChevronRight />
                </ButtonLink>
              </div>
            </div>
            {isDocumentUploadClicked ? (
              <>
                {!isUploadingDocument ? (
                  <>
                    <div
                      className={`advanced-setting-body mt-2 ${
                        !isAuthenticated ? 'disbaled-document-upload' : ''
                      }`}
                    >
                      {uploadedFilesList && uploadedFilesList.length > 0 ? (
                        <div className="chip-container my-3">
                          {uploadedFilesList && uploadedFilesList.length > 0 && uploadedFilesList.map((doc, index) => (
                            <div className='chip' key={`${doc.key_path + index}`}>
                              <span className='chip-label'>{doc.file_name}</span>
                              <AiFillDelete onClick={() => handleRemoveDocument(index)} className='chip-icon' />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="no-file mt-4">No file selected</div>
                      )}
                      {documentError !== '' || getDocumentUploadError !== undefined ? (
                        <div className="document-format-text my-3 document-error d-flex flex-items-center">
                          <MdError />
                          <div className="ml-2">
                            {documentError !== ''
                              ? documentError
                              : getDocumentUploadError}
                          </div>
                        </div>
                      ) : isMaxDocumentUploaded ? (
                        <div className="document-format-text my-3 document-error d-flex flex-items-center">
                          <MdError size={25} />
                          <div className="ml-2">
                            You have reached the upload limit of 5 documents. Please contact {' '}
                            <a className="text-primary" href="mailto:consult@atroposhealth.com">
                              consult@atroposhealth.com
                            </a>{' '} if you need to provide further documentation.
                          </div>
                        </div> )
                      : (
                        <div className="document-format-text my-3">
                          {Strings.documentExtensionNote}
                        </div>
                        )}
                      {
                      // documentFileName !== '' && !orderFormData?.previous_order_guid ? (
                      //   <>
                      //     <ButtonCustom
                      //       disabled={!isAuthenticated}
                      //       cssClass="btn-primary"
                      //       onClick={() => onClickOfDeleteDocument()}
                      //       buttonAction={'Delete document button clicked'}
                      //       actionLabel={'Deleted selected document'}
                      //       id={'btn-delete-document-button'}
                      //     >
                      //       Delete
                      //     </ButtonCustom>
                      //   </>
                      // ) : 
                      (
                        <>
                          {isAuthenticated ? (
                            <input
                              disabled={!isAuthenticated}
                              type="file"
                              name="document"
                              id="document-upload"
                              className="inputfile d-none"
                              accept=".docx,.pdf,.csv,.xlsx,.pptx"
                              value={filePath}
                              onChange={(e) => {
                                handleDocumentInputUpload(e);
                              }}
                              onClick={(e) => {
                                handleMaxDocUploadErr(e)
                                setFilePath('');
                              }}
                            />
                          ) : (
                            ''
                          )}
                          <label htmlFor="document-upload" className="inputfile-label">
                            Choose
                          </label>
                        </>
                      )}
                    </div>
                    {!isAuthenticated ? (
                      <div className="document-disabled-text">
                        To upload a document, please login or create an account{' '}
                        <ButtonCustom
                          onClick={() => OnClickOfLogin()}
                          cssClass="mt-2 login-btn"
                          buttonAction="Sign in button clicked"
                          id={'btn-login-button'}
                        >
                          here
                        </ButtonCustom>
                        .
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <div className="loader-btn-view">
                    <AuthLoader fullScreen={false} />
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>

        <div>
          <div className="advaced-settings-div mb-4">
            <div
              className="d-flex cursor-pointer"
              onClick={() => setAdvacedSettingBlock()}
              id={'advanced-setting-optionn'}
            >
              <div className="advanced-setting-title">Advanced Settings </div>
              <div
                className={
                  !isButtonClicked
                    ? 'button-position right-arrow'
                    : 'button-position right-arrow rotate-button'
                }
              >
                <ButtonLink
                  buttonAction="Advanced setting clicked"
                  id={'advaced-setting-icon'}
                >
                  <HiOutlineChevronRight />
                </ButtonLink>
              </div>
            </div>
            {isButtonClicked ? (
              <div className="advanced-setting-body mt-2">
                <div
                  className={`w-50 data-source-container mb-3 position-relative ${
                    orderFormData?.previous_order_guid &&
                    orderFormData?.orderStatus === 'Pending' &&
                    orderFormData?.isEditOrder
                      ? 'disabled-data'
                      : ''
                  }`}
                >
                  <div className="heading-text mb-3">Data Sources</div>
                  <div className="mb-2 sub-heading-text">
                    To learn more about our data sources,{' '}
                    <a
                      className=""
                      href={'mailto:support@atroposhealth.com'}
                      id={'email-click-here'}
                    >
                      click here
                    </a>
                  </div>
                  <div ref={dataSourceDivRef}>
                    <div
                      className="position-relative"
                      onClick={() => onClickOfDataSource()}
                      id={'data-source-selection-dropdown'}
                    >
                      <div
                        className={`data-source ${
                          dataTypeValue === '' ? 'placeholder-color' : ''
                        }`}
                      >
                        {dataTypeValue === '' ? 'Select data source' : dataTypeValue}
                      </div>
                      <ButtonLink
                        cssClass="position-absolute"
                        buttonAction="Select data source clicked"
                        id={'data-source-dropdown-arrow'}
                      >
                        <HiOutlineChevronDown />
                      </ButtonLink>
                    </div>
                    {isShowDataSetDropDown ? (
                      <div className="d-flex-toggle d-flex flex-justify-center data-source-list mt-1 position-absolute">
                        {getDataSourceList()}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="display-advaced-settings-block mt-4">
                  <div className="heading-text mt-3 mb-2">Sharing</div>
                  <div className="button-container d-md-flex">
                    <div className={`toolTip-div pr-4 `} key={'index'}>
                      <Checkbox
                        buttonName={'Confidential'}
                        onChange={handleCheck}
                        name={'sharing-1'}
                        value={isConfidential}
                        checked={isConfidential ? true : false}
                        id={'checkbox-confidential-sharing-setting'}
                      />
                      <div
                        className="tooltip-container container-tooltip-box"
                        key={'index'}
                      >
                        <div
                          className={
                            showTooltipConfidential
                              ? 'tooltip-box visible set-div-width'
                              : 'tooltip-box'
                          }
                          ref={refConfidential}
                        >
                          <div
                            className="mx-1 my-1"
                            onClick={() => {
                              setShowTooltipConfidential(false);
                              mixpanel.track('Confidential tooltip closed event');
                            }}
                          >
                            By checking this box, you are choosing to keep the question
                            and resulting Prognostogram confidential for 30 days. After 30
                            days, Atropos may share the resulting Prognostogram with
                            authorized users based on your organization's preferences.
                            Please contact us at{' '}
                            <a className="" href="mailto:support@atroposhealth.com">
                              support@atroposhealth.com
                            </a>{' '}
                            if you have any questions.
                          </div>
                          <span className="tooltip-arrow" />
                        </div>
                        <div
                          className="tooltip-button d-md-flex my-2"
                          onMouseEnter={() => {
                            setShowTooltipConfidential(true);
                            mixpanel.track('Confidential tooltip opened event');
                          }}
                          onClick={() => {
                            setShowTooltipConfidential(!showTooltipConfidential);
                            mixpanel.track(
                              'Confidential tooltip ' + showTooltipConfidential
                                ? 'opened'
                                : 'closed' + ' event',
                            );
                          }}
                        >
                          <ImInfo />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="d-md-flex flex-md-justify-between">
          <ButtonCustom
            cssClass={`gradient-btn send-request-btn mt-md-0 mt-3 ${
              !formState.questionText.value ||
              (isUploadingDocument && documentFileName !== '')
                ? 'btn-disabled'
                : ''
            }`}
            onClick={submitOrder}
            buttonAction={'Submit order button clicked'}
            actionLabel={'New PG order is submitted'}
            id={'btn-submit-order-button'}
          >
            Confirm and send request
          </ButtonCustom>
          <ButtonCustom
            cssClass="cancel-btn mt-md-0 mt-3"
            onClick={onCancelRequest}
            id={'btn-cancel-order-button'}
            buttonAction={
              orderFormData?.previous_order_guid && orderFormData?.isEditOrder
                ? 'Discard Changes edit order clicked'
                : 'Cancel new PG order clicked'
            }
            actionLabel={
              orderFormData?.previous_order_guid && orderFormData?.isEditOrder
                ? 'Edit order changes discarded'
                : 'New PG order canceled'
            }
          >
            {orderFormData?.previous_order_guid && orderFormData?.isEditOrder
              ? 'Discard Changes'
              : 'Cancel Request'}
          </ButtonCustom>
        </div>
        {!!orderPrognostogramError || formErrors !== '' ? (
          <div className="color-error error-text">
            {formErrors ? formErrors : orderPrognostogramError}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default SinglePageOrderRequest;
