import React, { useState, useEffect } from 'react';
import CustomModal from '../components/CustomModal';
import OnboardingPricing from '../components/OnboardingPricing';
import TermsOfServices from '../components/TermsOfServices';
import OnboardingProfile from '../components/OnboardingProfile';
import onBoardingStepsEnum from '../constants/enum/onBoardingStepsEnum';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProfessionList,
  getSpecialtyDropDownList,
} from '../redux/modules/userProfile/actions';
import { getRawToken } from '../utils/validations';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoader from '../components/AuthLoader';
import urlConstantsEnum from '../constants/enum/urlConstantsEnum';
import { useHistory } from 'react-router-dom';
import { Strings } from '../constants/Strings';

const Onboarding = ({ show, onboardingStep, closeButton }) => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const [onBoradingSteps, setOnboradingSteps] = useState(
    onboardingStep ? onboardingStep : onBoardingStepsEnum.PERSONAL_INFO,
  );
  const { waitingForProfessionList, waitingForSpecialtyList, userData } = useSelector(
    ({ userProfile }) => userProfile,
  );
  const showHeaders = () => {
    return (
      <>
        {userData?.is_existing_user &&
        onBoradingSteps === onBoardingStepsEnum.PERSONAL_INFO ? (
          <div className="existing-user-text mb-2">
            {Strings.onboardingExistingUserText}
          </div>
        ) : (
          ''
        )}
        {userData?.is_existing_user && !userData?.agreed_terms_of_service ? (
          <div className="existing-user-text mb-2">
            {Strings.onboardingExistingUserTextForTOS}
          </div>
        ) : (
          ''
        )}
        {userData?.is_existing_user &&
        !userData?.agreed_terms_of_service &&
        userData?.is_profile_completed ? (
          ''
        ) : (
          <>
            <div className="header-number">
              Step{' '}
              {onBoradingSteps === onBoardingStepsEnum.PLAN_AND_PRICING
                ? '3'
                : onBoradingSteps === onBoardingStepsEnum.TERMS_OF_SERVICE
                ? 2
                : 1}{' '}
              of {userData?.subscription_information === null ? 3 : 2}
            </div>
            <div
              className={`onboarding-header d-flex my-2 flex-justify-between ${
                userData?.subscription_information !== null ? 'two-step' : ''
              }`}
            >
              <div className={`active-header`}></div>
              <div
                className={`${
                  onBoradingSteps === onBoardingStepsEnum.PLAN_AND_PRICING ||
                  onBoradingSteps === onBoardingStepsEnum.TERMS_OF_SERVICE
                    ? 'active-header'
                    : ''
                }`}
              ></div>
              {userData?.subscription_information === null ? (
                <div
                  className={`${
                    onBoradingSteps === onBoardingStepsEnum.PLAN_AND_PRICING
                      ? 'active-header'
                      : ''
                  }`}
                ></div>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    const getProfessionsList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getProfessionList({
          token: getRawToken(token),
        }),
      );
    };
    const getSpecialtiesDropDownList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getSpecialtyDropDownList({
          token: getRawToken(token),
        }),
      );
    };
    getSpecialtiesDropDownList();
    getProfessionsList();
  }, [dispatch]);

  useEffect(() => {
    if (
      userData?.is_existing_user &&
      !userData?.agreed_terms_of_service &&
      userData?.is_profile_completed
    ) {
      console.log('.....');
      history.push(urlConstantsEnum.ONBOARDING_STEP_2);
      setOnboradingSteps(onBoardingStepsEnum.TERMS_OF_SERVICE);
    }
  }, [userData]);

  const onClickOfProfileBtn = () => {
    history.push(urlConstantsEnum.ONBOARDING_STEP_2);
    setOnboradingSteps(onBoardingStepsEnum.TERMS_OF_SERVICE);
  };
  const onClickOfTosBtn = () => {
    history.push(urlConstantsEnum.ONBOARDING_STEP_3);
    setOnboradingSteps(onBoardingStepsEnum.PLAN_AND_PRICING);
  };
  const onClickOfTosBackBtn = () => {
    history.push(urlConstantsEnum.ONBOARDING_STEP_1);
    setOnboradingSteps(onBoardingStepsEnum.PERSONAL_INFO);
  };

  const onClickOfCloseButton = () => {
    !!closeButton && closeButton();
    history.push(urlConstantsEnum.ONBOARDING_STEP_1);
    setOnboradingSteps(onBoardingStepsEnum.PERSONAL_INFO);
  };

  return (
    <>
      <CustomModal
        isOpen={show}
        handleClose={onClickOfCloseButton}
        cssClass="white-overlay"
        modalname="Onboarding modal"
        overlayCssClass={'onboarding-modal-overlay'}
      >
        <div className="email-modal p-md-5 p-3 onboarding-modal text-left">
          {waitingForProfessionList || waitingForSpecialtyList ? (
            <AuthLoader fullScreen={false} />
          ) : (
            <div className="">
              {showHeaders()}
              {onBoradingSteps === onBoardingStepsEnum.PERSONAL_INFO && (
                <OnboardingProfile onClickOfProfileBtn={onClickOfProfileBtn} />
              )}
              {onBoradingSteps === onBoardingStepsEnum.TERMS_OF_SERVICE && (
                <TermsOfServices
                  onClickOfTosBackBtn={onClickOfTosBackBtn}
                  onClickOfTosBtn={onClickOfTosBtn}
                />
              )}
              {onBoradingSteps === onBoardingStepsEnum.PLAN_AND_PRICING && (
                <OnboardingPricing onClickOfPricingBackBtn={onClickOfProfileBtn} />
              )}
            </div>
          )}
        </div>
        <div className="onboading-bg"></div>
      </CustomModal>
    </>
  );
};
export default Onboarding;
