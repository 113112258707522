import {
  GET_ORDER_DETAILS_START,
  GET_ORDER_DETAILS_COMPLETE,
  GET_ORDER_DETAILS_ERROR,
  GET_SEARCH_QUERY_DETAILS_START,
  GET_SEARCH_QUERY_DETAILS_COMPLETE,
  GET_SEARCH_QUERY_DETAILS_ERROR,
  GET_SEARCH_DATA_START,
  GET_SEARCH_DATA_COMPLETE,
  GET_SEARCH_DATA_ERROR,
  GET_ORDER_STATUS_COUNT_START,
  GET_ORDER_STATUS_COUNT_COMPLETE,
  GET_ORDER_STATUS_COUNT_ERROR,
  SET_ERROR,
  SET_LOADING,
  RESET_DATA,
  GET_QUESTION_TYPES_START,
  GET_QUESTION_TYPES_COMPLETE,
  GET_QUESTION_TYPES_ERROR,
  ORDER_PROGNOSTOGRAM_START,
  ORDER_PROGNOSTOGRAM_COMPLETE,
  ORDER_PROGNOSTOGRAM_ERROR,
  MODIFY_PROGNOSTOGRAM_ORDER_START,
  MODIFY_PROGNOSTOGRAM_ORDER_COMPLETE,
  MODIFY_PROGNOSTOGRAM_ORDER_ERROR,
  SET_ORDER_FORM_DATA,
  SET_ORDER_SCOPE_DATA,
  SET_SEARCH_QUERY_TEXT,
  SET_SPECIALTY_TEXT,
  CLEAR_ORDER_FORM_DATA,
  GET_PROGNOSTOGRAM_PDF_START,
  GET_PROGNOSTOGRAM_PDF_COMPLETE,
  GET_PROGNOSTOGRAM_PDF_ERROR,
  DELETE_ORDER_START,
  DELETE_ORDER_COMPLETE,
  DELETE_ORDER_ERROR,
  GET_SPECIALTY_LIST_START,
  GET_SPECIALTY_LIST_COMPLETE,
  GET_SPECIALTY_LIST_ERROR,
  RESET_ORDER_INDEX_DATA,
  SET_ORDER_INDEX_DATA,
  GET_ORDER_BY_GUID_START,
  GET_ORDER_BY_GUID_COMPLETE,
  GET_ORDER_BY_GUID_ERROR,
  RESET_SINGLE_ORDER_DATA,
  UPLOAD_ORDER_DOCUMENT_START,
  UPLOAD_ORDER_DOCUMENT_COMPLETE,
  UPLOAD_ORDER_DOCUMENT_ERROR,
  RESET_UPLOADED_DOCUMENT_DATA,
  GET_ORDER_DOCUMENT_START,
  GET_ORDER_DOCUMENT_COMPLETE,
  GET_ORDER_DOCUMENT_ERROR,
  RESET_ORDER_DOCUMENT,
  GET_MOTIVATIONS_START,
  GET_MOTIVATIONS_COMPLETE,
  GET_MOTIVATIONS_ERROR,
  GET_DOWNLOAD_LINK_START,
  GET_DOWNLOAD_LINK_COMPLETE,
  GET_DOWNLOAD_LINK_ERROR,
  RESET_DOWNLOAD_LINK,
  RESET_PROGNOSTOGRAM_PDF,
  SET_SEARCH_ORDER_LOADING,
  SET_UPLOADED_FILE,
  REMOVE_UPLOADED_FILE,
  CLEAR_UPLOADED_DOCUMENTS_LIST
} from './actions';
import { ORDER_PAGINATION_SIZE } from 'constants/Constants';

let initialState = {
  isOrderLoading: false,
  questionData: [],
  orderData: {},
  getQuestionError: undefined,
  orderPrognostogramError: undefined,
  getOrderError: undefined,
  getSearchQueryDeatilsError: undefined,
  searchListData: {},
  pdfFile: '',
  pageNumber: 1,
  hasNoOrders: false,
  hasMoreOrders: false,
  hasMoreSearchOrders: false,
  searchPageNumber: 1,
  orderListCount: 0,
  orderIndexData: {},
  isUploadingDocument: false,
  getDocumentUploadError: undefined,
  fileDowloadLink: undefined,
  isSearchOrderLoading: false,
  uploadedFilesList: []
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_SPECIALTY_LIST_START:
      return {
        ...state,
        getSpecialtyListError: undefined,
      };
    case GET_SPECIALTY_LIST_COMPLETE:
      const specialtyList = [...payload.specialtyData];

      return {
        ...state,
        specialtyData: specialtyList,
        getSpecialtyListError: undefined,
      };
    case GET_SPECIALTY_LIST_ERROR:
      return { ...state, getSpecialtyListError: payload.error, ...payload };
    case RESET_SINGLE_ORDER_DATA:
      return {
        ...state,
        singleOrderData: payload,
        ...payload,
      };
    case GET_ORDER_BY_GUID_START:
      return {
        ...state,
        getOrderDetailError: undefined,
        isgettingOrderDetails: true,
      };
    case GET_ORDER_BY_GUID_COMPLETE:
      return {
        ...state,
        singleOrderData: payload.data,
        getOrderDetailError: undefined,
        isgettingOrderDetails: false,
      };
    case GET_ORDER_BY_GUID_ERROR:
      return {
        ...state,
        getOrderDetailError: payload.error,
        singleOrderData: {},
        isgettingOrderDetails: false,
        ...payload,
      };
    case UPLOAD_ORDER_DOCUMENT_START:
      return {
        ...state,
        getDocumentUploadError: undefined,
        isUploadingDocument: true,
      };
    case UPLOAD_ORDER_DOCUMENT_COMPLETE:
      return {
        ...state,
        uploadedDocumentInfo: payload.data,
        getDocumentUploadError: undefined,
        isUploadingDocument: false,
      };
    case UPLOAD_ORDER_DOCUMENT_ERROR:
      return {
        ...state,
        getDocumentUploadError: payload.error,
        uploadedDocumentInfo: {},
        isUploadingDocument: false,
        ...payload,
      };
    case RESET_UPLOADED_DOCUMENT_DATA:
      return {
        ...state,
        getDocumentUploadError: undefined,
        uploadedDocumentInfo: {},
        isUploadingDocument: false,
        ...payload,
      };
    case GET_DOWNLOAD_LINK_START:
      return {
        ...state,
        resource: undefined,
        getFileDownloadError: undefined,
        fileDowloadLink: undefined,
        isFetchingDownloadLinkStarted: true,
      };
    case GET_DOWNLOAD_LINK_COMPLETE:
      return {
        ...state,
        resource: payload.resource,
        getFileDownloadError: undefined,
        fileDowloadLink: payload.data,
        isFetchingDownloadLinkStarted: false,
      };
    case GET_DOWNLOAD_LINK_ERROR:
      return {
        ...state,
        resource: payload.resource,
        getFileDownloadError: payload.error,
        fileDowloadLink: undefined,
        isFetchingDownloadLinkStarted: false,
        ...payload,
      };
    case RESET_DOWNLOAD_LINK:
      return {
        ...state,
        resource: undefined,
        fileDowloadLink: undefined,
        isFetchingDownloadLinkStarted: false,
        getFileDownloadError: undefined,
        ...payload,
      };
    case GET_QUESTION_TYPES_START:
      return {
        ...state,
        getQuestionError: undefined,
      };
    case GET_QUESTION_TYPES_COMPLETE:
      const questionList = [...payload.questionData];

      return {
        ...state,
        questionData: questionList,
        getQuestionError: undefined,
      };
    case GET_QUESTION_TYPES_ERROR:
      return { ...state, getQuestionError: payload.error, ...payload };

    case GET_MOTIVATIONS_START:
      return {
        ...state,
        getMotivationsError: undefined,
      };
    case GET_MOTIVATIONS_COMPLETE:
      let motivations = payload.motivationsData.map(motivationText => ({category: motivationText, value: false}))
      const research = motivations.find(motivation => motivation.category == "Research")
      const patientCare = motivations.find(motivation => motivation.category == "Patient Care")
      const careProtocol = motivations.find(motivation => motivation.category == "Care Protocol")
      const unorderedMotivations = motivations.filter(motivation => motivation.category  != "Research" && motivation.category  != "Patient Care" && motivation.category  != "Care Protocol")
      motivations = [research, patientCare, careProtocol, ...unorderedMotivations]
      motivations = motivations.filter(motivation => motivation) //filter in case any of the above motivations not found

      return {
        ...state,
        motivations: motivations,
        getMotivationsError: undefined,
      };
    case GET_MOTIVATIONS_ERROR:
      return { ...state, getMotivationsError: payload.error, ...payload };

    case ORDER_PROGNOSTOGRAM_START:
      return {
        ...state,
        orderPrognostogramError: undefined,
      };
    case ORDER_PROGNOSTOGRAM_COMPLETE:
      return {
        ...state,
        orderPrognostogramError: undefined,
      };
    case ORDER_PROGNOSTOGRAM_ERROR:
      return { ...state, orderPrognostogramError: payload.error, ...payload };
    case MODIFY_PROGNOSTOGRAM_ORDER_START:
      return {
        ...state,
        orderPrognostogramError: undefined,
      };
    case MODIFY_PROGNOSTOGRAM_ORDER_COMPLETE:
      return {
        ...state,
        orderPrognostogramError: undefined,
      };
    case MODIFY_PROGNOSTOGRAM_ORDER_ERROR:
      return { ...state, orderPrognostogramError: payload.error, ...payload };
    case GET_ORDER_DETAILS_START:
      return {
        ...state,
        getOrderError: undefined,
        waitingForOrders: true,
        orderListCount: 0,
      };
    case GET_ORDER_DETAILS_COMPLETE:
      let canPaginate = false;
      let noOrders = false;
      const orderList =
        payload.pageNumber === 1 || payload.isIndexSet
          ? [...payload.orderData]
          : [...state.orderData, ...payload.orderData];

      if (payload.pageNumber === 1 && orderList.length === 0) {
        noOrders = true;
      }

      if (payload.total_pages - payload.pageNumber > 0) {
        canPaginate = true;
      } else {
        canPaginate = false;
      }
      return {
        ...state,
        hasMoreOrders: canPaginate,
        orderData: orderList,
        pageNumber: payload.pageNumber + 1,
        hasNoOrders: noOrders,
        getOrderError: undefined,
        waitingForOrders: false,
        orderListCount: payload.orderListCount,
      };
    case GET_ORDER_DETAILS_ERROR:
      return {
        ...state,
        getOrderError: payload.error,
        waitingForOrders: false,
        ...payload,
      };
    case GET_SEARCH_QUERY_DETAILS_START:
      return {
        ...state,
        getSearchQueryDeatilsError: undefined,
      };
    case GET_SEARCH_QUERY_DETAILS_COMPLETE:
      return {
        ...state,
        searchQueryListData: payload.searchData,
        getSearchQueryDeatilsError: undefined,
      };
    case GET_SEARCH_QUERY_DETAILS_ERROR:
      return { ...state, getSearchQueryDeatilsError: payload.error, ...payload };
    case GET_SEARCH_DATA_START:
      return {
        ...state,
        getOrderBySearchError: undefined,
      };
    case GET_SEARCH_DATA_COMPLETE:
      let canSearchPaginate = false;
      let noSearchOrders = false;
      const searchOrderList =
        payload.searchPageNumber === 1 || payload.isIndexSet
          ? [...payload.searchData]
          : [...state.searchListData, ...payload.searchData];

      if (payload.searchPageNumber === 1 && searchOrderList.length === 0) {
        noSearchOrders = true;
      }
      if (payload.total_pages - payload.searchPageNumber > 0) {
        canSearchPaginate = true;
      } else {
        canSearchPaginate = false;
      }
      return {
        ...state,
        hasMoreSearchOrders: canSearchPaginate,
        searchPageNumber: payload.searchPageNumber + 1,
        hasNoOrders: noSearchOrders,
        searchListData: searchOrderList,
        getOrderBySearchError: undefined,
        count: payload.count,
      };
    case GET_SEARCH_DATA_ERROR:
      return { ...state, getOrderBySearchError: payload.error, ...payload };

    case GET_ORDER_STATUS_COUNT_START:
      return {
        ...state,
        getOrderStatusError: undefined,
      };
    case GET_ORDER_STATUS_COUNT_COMPLETE:
      return {
        ...state,
        orderStatusCountData: payload.orderStatusData,
        getOrderStatusError: undefined,
      };
    case GET_ORDER_STATUS_COUNT_ERROR:
      return { ...state, getOrderStatusError: payload.error, ...payload };
    case GET_PROGNOSTOGRAM_PDF_START:
      return {
        ...state,
        pdfFile: undefined,
        isPdfLoading: true,
      };
    case GET_PROGNOSTOGRAM_PDF_COMPLETE:
      return {
        ...state,
        pdfFile: payload.pdfFile,
        getPdfFileError: undefined,
        isPdfLoading: false,
      };
    case GET_PROGNOSTOGRAM_PDF_ERROR:
      return {
        ...state,
        getPdfFileError: payload.error,
        ...payload,
        isPdfLoading: false,
      };
    case RESET_PROGNOSTOGRAM_PDF:
      return {
        ...state,
        pdfFile: undefined,
        getPdfFileError: undefined,
        isPdfLoading: false,
        ...payload,
      };
    case DELETE_ORDER_START:
      return {
        ...state,
        deleteOrderError: undefined,
      };
    case DELETE_ORDER_COMPLETE:
      return {
        ...state,
        deleteOrderError: undefined,
      };
    case DELETE_ORDER_ERROR:
      return { ...state, deleteOrderError: payload.error, ...payload };
    case SET_ERROR:
      return { ...state, isOrderError: payload.data };
    case SET_LOADING:
      return { ...state, isOrderLoading: payload.data };
    case RESET_DATA:
      return {
        ...state,
        getQuestionError: undefined,
        orderPrognostogramError: undefined,
      };
    case SET_ORDER_FORM_DATA:
      return {
        ...state,
        orderFormData: payload,
        ...payload,
      };
    case SET_ORDER_INDEX_DATA:
      return {
        ...state,
        orderIndexData: payload,
        ...payload,
      };
    case RESET_ORDER_INDEX_DATA:
      return {
        ...state,
        orderIndexData: payload,
        ...payload,
      };
    case SET_ORDER_SCOPE_DATA:
      return {
        ...state,
        orderScopeData: payload,
        ...payload,
      };
    case SET_SEARCH_QUERY_TEXT:
      return {
        ...state,
        searchData: payload,
        ...payload,
      };
    case SET_SPECIALTY_TEXT:
      return {
        ...state,
        specialtyTextData: payload,
        ...payload,
      };
    case CLEAR_ORDER_FORM_DATA:
      return {
        ...state,
        orderFormData: {},
        ...payload,
      };
    case SET_SEARCH_ORDER_LOADING:
      return { ...state, isSearchOrderLoading: payload };
      case SET_UPLOADED_FILE:
        if(Object.prototype.toString.call(payload) === '[object Array]') {
          return {
            ...state,
            uploadedFilesList:[...state.uploadedFilesList, ...payload]
          }
        } else {
          return {
            ...state,
            uploadedFilesList:[...state.uploadedFilesList, payload]
          }
        }
      case REMOVE_UPLOADED_FILE:
        var files = [...state.uploadedFilesList];
        files.splice(payload, 1)
      return {
        ...state,
        uploadedFilesList:files
      }
      case CLEAR_UPLOADED_DOCUMENTS_LIST:
        return {
          ...state,
          uploadedFilesList:[]
        }
    default:
      return state;
  }
};

export default reducer;
